import { IconArrowNarrowLeft, IconInfoCircleFilled } from "@tabler/icons-react";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { buyCoin } from "../../service/api";
import { Danger } from "../Core/Errors";
import Loader from "../Core/Loader";
import { getLocale } from "../../constants/language";

function OrderSummary() {
  const navigate = useNavigate();
  const { activeCoin } = useLocation().state;
  const [loader, setLoader] = useState(false);

  const purchaseNow = () => {
    try {
      setLoader(true);
      const payload = {
        coin: activeCoin?.coin?._id,
        type: "BUY",
        fee: 0.0,
        price: Number(activeCoin?.coin?.price),
        quantity: activeCoin?.qty,
        paymentMethod: "Stripe",
        currency: "usd",
      };
      buyCoin(payload)
        .then((res) => {
          //
          setLoader(false);
          window.location.href = res?.data?.url;
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };
  return (
    <div>
      {loader && <Loader />}
      <div className="h-full min-h-[100vh] relative bg-white font-sans pt-12 pb-3 flex flex-col justify-between ">
        <div>
          <div className=" flex justify-between items-center px-4">
            <IconArrowNarrowLeft
              className="size-7 cursor-pointer"
              // onClick={() => navigate("/addPaymentMethod")}
              onClick={() =>
                navigate(-1, {
                  state: {
                    coin: activeCoin?.coin,
                    price: activeCoin?.coin?.price,
                    activeCoin: activeCoin,
                  },
                })
              }
            />
            <h1 className="font-medium leading-[145%] tracking-[-1px] text-[18px] text-[#111111] ">
              {/* Order Summary */}
              {getLocale("orderSummary")}{" "}
            </h1>
            <p></p>
          </div>
          <div className="mt-[50px] px-4 ">
            <h1 className="font-medium leading-[145%] tracking-[-1px] text-[22px] text-[#111111] text-center">
              {`${activeCoin?.qty} ${activeCoin?.coin?.symbol}`}
            </h1>
          </div>

          <div className=" mt-5 border-t border-t-[#CFCFCF]">
            <div className="px-4 mt-4">
              <div className=" flex justify-between items-center">
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal">
                  {/* KMBIO price */}
                  {getLocale("KMBIOPrice")}
                </p>
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal">
                  {activeCoin?.coin?.price}
                </p>
              </div>
            </div>
            <div className="px-4 mt-4">
              <div className=" flex justify-between items-center">
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal">
                  {/* Payment method */}
                  {getLocale("paymentMethod")}
                </p>
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal">
                  {/* Stripe */}
                  {getLocale("stripe")}
                </p>
              </div>
            </div>
            <div className="px-4 mt-4">
              <div className=" flex justify-between items-center">
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal">
                  {/* Purchase */}
                  {getLocale("purchase")}
                </p>
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal">
                  {`${activeCoin?.coin?.symbol} ${activeCoin?.qty}`}
                </p>
              </div>
            </div>
            <div className="px-4 mt-8 ">
              <div className=" flex justify-between items-center">
                <div>
                  <div className="flex items-center gap-2">
                    <p className="text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal">
                      {/* Fee */}
                      {getLocale("fee")}
                    </p>
                    <IconInfoCircleFilled size={15} />
                  </div>
                  <p className="text-[14px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal">
                    {/* Including taxes */}
                    {getLocale("incTax")}
                  </p>
                </div>
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal">
                  0
                </p>
              </div>
            </div>
            <div className="px-4 mt-4">
              <div className=" flex justify-between items-center">
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-semibold">
                  {/* Total */}
                  {getLocale("total")}
                </p>
                <p className=" text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-semibold">
                  $ {activeCoin?.amount}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-[50px] px-4">
            <p className="text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal text-left">
              Processed by <span className=" font-semibold">KMBIO LTD</span>
            </p>
            <p className="text-[16px] mt-1 leading-[140%] tracking-[0.2px] text-[#707070] font-normal text-left">
              Crypto markets are unique.{" "}
              <Link className=" text-[#6DA8FF] ">Learn more.</Link>
            </p>
          </div>
        </div>
        <div className="px-4 py-4 border-t border-t-[#cfcfcf] flex items-center justify-center">
          <button
            onClick={purchaseNow}
            className="w-full p-4 bg-bgBlue text-white rounded-lg  text-base"
          >
            {/* Purchase now */}
            {getLocale("purchaseNow")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default OrderSummary;
