import React, { useEffect, useState } from "react";
import CoinSlider from "./CoinSlider";
import {
  IconArrowsExchange2,
  IconCaretDownFilled,
  IconChevronLeft,
  IconFlagPlus,
  IconInfoCircle,
  IconMinus,
  IconPlus,
  IconX,
} from "@tabler/icons-react";
import { getLocale } from "../../../constants/language";

import CurrencySlider from "./CurrencySlider";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import SelectPayMethods from "./SelectPayMethods";
import Tags from "./Tags";
import Region from "./Regions";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/user/actions";
import {
  addP2pOrder,
  getAllPayMethodByUser,
  getAllPaymethods,
} from "../../../service/api";
import { Danger, Success, Warning } from "../../Core/Errors";
import { useNavigate } from "react-router-dom";
import Loader from "../../Core/Loader";
import {
  step1Error,
  step2Error,
  step3Error,
  validateP2pAddPayload,
} from "./P2PError";
import { NumericFormat } from "react-number-format";
import { ROUTES } from "../../../constants/routes";
import { formatValue } from "../../../service/utils";
export const p2pAddPayloadreset = {
  coin: null,
  type: "BUY",
  fiatCurrency: null,
  price: null,
  minLimit: null,
  maxLimit: null,
  paymentMethods: [],
  paymentTimeLimit: null,
  amount: null,
  remarks: null,
};

const PostAdForm = () => {
  const { selectedCoin, selectedCurrency, p2pAddPayload, p2pFormStep } =
    useSelector((state) => state.user);
  console.log("p2pAddPayload: ", p2pAddPayload);
  const [error, setError] = useState({
    coin: "",
    fiatCurrency: "",
    price: "",
    minLimit: "",
    maxLimit: "",
    paymentMethods: "",
    paymentTimeLimit: "",
    amount: "",
    remarks: "",
  });
  console.log("error: ", error);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(true);
  const [currFilter, setCurrFilter] = useState(false);
  const [coinFilter, setCoinFilter] = useState(false);
  const [selectPaymethodSlider, setSelectPaymethodSlider] = useState(false);
  const [selectPaymethod, setselectPaymethod] = useState([]);

  const [isTags, setisTags] = useState(false);
  const [selectedTag, setSelectedTag] = useState([]);
  const [isregion, setIsregion] = useState(false);
  const [selectedregion, setSelectedRegion] = useState([]);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const getAdminAddedPayMethods = () => {
    try {
      setLoader(true);
      getAllPaymethods(pageNumber, true, sizePerPage)
        .then((res) => {
          setData(res.data?.result);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error: ", error);
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      console.log("error: ", error);
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };

  const getAllPaymethodsByUser = () => {
    try {
      setLoader(true);
      getAllPayMethodByUser(pageNumber, true, sizePerPage)
        .then((res) => {
          setData(res?.data?.result);
          setLoader(false);
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (p2pAddPayload?.type === "BUY") {
      getAdminAddedPayMethods();
    } else {
      getAllPaymethodsByUser();
    }
  }, [p2pAddPayload.type]);

  // Function to move to the next step
  const nextStep = () => {
    if (p2pFormStep < 3) {
      dispatch(actions.setP2PFromStep(p2pFormStep + 1));
    }
  };

  // Function to move to the previous step
  const prevStep = () => {
    if (p2pFormStep > 1) dispatch(actions.setP2PFromStep(p2pFormStep - 1));
  };

  const handleSubmit = () => {
    const error = step3Error(p2pAddPayload);
    setError({ ...error.errors });
    if (error.isvalid) {
      try {
        setLoader(true);
        try {
          const payload = {
            ...p2pAddPayload,
            price: Number(p2pAddPayload?.price),
            minLimit: Number(p2pAddPayload?.minLimit),
            maxLimit: Number(p2pAddPayload?.maxLimit),
            amount: Number(p2pAddPayload?.amount),
            coin: p2pAddPayload?.coin?.coin?._id,
            fiatCurrency: p2pAddPayload?.fiatCurrency?.fiatCurrency,
          };

          addP2pOrder({ ...payload })
            .then((res) => {
              Success(res?.message).then((_) => {
                dispatch(actions.setP2PPaylaod({ ...p2pAddPayloadreset }));
                navigate(ROUTES.MYADDS);
              });
              setLoader(false);
            })
            .catch((error) => {
              Danger(error?.response?.data?.message);
              setLoader(false);
            });
        } catch (error) {
          Danger(error?.response?.data?.message);
          setLoader(false);
        }
      } catch (error) {}
    }
  };
  return (
    <div className="  relative overflow-x-hidden">
      {loader && <Loader />}
      <div className="h-full min-h-[100vh] relative font-sansflex flex-col">
        <div className=" flex justify-between items-center pt-12 w-full px-4 py-4 bg-bgBlue">
          <IconChevronLeft
            onClick={() => {
              if (p2pFormStep === 1) {
                navigate(-1);
                dispatch(actions.setP2PPaylaod({ ...p2pAddPayloadreset }));
              } else {
                prevStep();
              }
            }}
            color="white"
            className="size-7 cursor-pointer"
          />
          <h1 className=" flex justify-center items-center text-textWhite font-bold tracking-[1.02px] leading-[140%] gap-1">
            {/* Add  */}
            {getLocale("add")} P2P <IconArrowsExchange2 color="white" />
          </h1>
          <div>
            {/* <select value={selectedCurrency} onChange={(e) => setSelectedCurrency(e.target.value)} className='outline-none border-[1px] border-borderWhite rounded-sm'>
                            {
                                currList?.map((curr, index) => (
                                    <option value={curr}>{curr}</option>
                                ))
                            }
                        </select> */}
          </div>
        </div>

        <div className="h-full w-full min-h-[88vh] bg-bgWhite rounded-tl-xl px-4 rounded-tr-xl py-4 z-10 ">
          {/* Step Indicator with Progress Bar */}
          <div className="relative flex justify-between items-center mb-6 ">
            {/* Background Line */}
            <div className="absolute top-1/2 transform -translate-y-1/2 w-full h-1 bg-gray-300 rounded-full"></div>

            {/* Step Circles */}
            {[1, 2, 3].map((s) => (
              <div key={s} className="relative z-10">
                <div
                  className={`w-8 h-8 flex items-center justify-center rounded-full border-2 ${
                    p2pFormStep >= s
                      ? " bg-bgBlue text-white"
                      : "border-gray-400 bg-white text-gray-400"
                  } transition-all duration-500`}
                >
                  {s}
                </div>
              </div>
            ))}

            {/* Progress Line */}
            <div
              className={`absolute top-1/2 transform -translate-y-1/2 h-1 bg-bgBlue rounded-full transition-all duration-500`}
              style={{ width: `${(p2pFormStep - 1) * 50}%` }} // Width grows as step increases
            ></div>
          </div>
          {/* Step 2: Asset and Fiat Selection */}

          {p2pFormStep === 1 && (
            <div className="transition-opacity duration-500 flex flex-col justify-between">
              <div>
                <div className="">
                  <p className=" text-left text-[14px] tracking-[-0.2px] leading-[140%]  mb-1">
                    {/* I want to */}
                    {getLocale("iWantTo")}
                  </p>
                  <div className="flex justify-between text-[16px] leading-[140%]  items-center bg-bgWhite border min-w-[100px] rounded-sm ">
                    <button
                      onClick={() => {
                        dispatch(
                          actions.setP2PPaylaod({
                            ...p2pAddPayload,
                            type: "BUY",
                            coin: "",
                            fiatCurrency: "",
                          })
                        );
                      }}
                      className={`  transition-all px-2 py-1.5 ${
                        p2pAddPayload?.type === "BUY"
                          ? "bg-bgBlue text-white"
                          : ""
                      } rounded-sm w-1/2 h-full`}
                    >
                      {/* Buy */}
                      {getLocale("buy")}
                    </button>
                    <button
                      onClick={() => {
                        dispatch(
                          actions.setP2PPaylaod({
                            ...p2pAddPayload,
                            type: "SELL",
                            coin: "",
                            fiatCurrency: "",
                          })
                        );
                      }}
                      className={` transition-all px-2 py-1.5 ${
                        p2pAddPayload?.type === "SELL"
                          ? "bg-bgBlue text-white"
                          : ""
                      } rounded-sm w-1/2 h-full`}
                    >
                      {/* Sell */}
                      {getLocale("sell")}
                    </button>
                  </div>
                </div>
                <div className=" flex justify-between w-full gap-1 mt-3">
                  <div className="mb-4 w-1/2">
                    <label className="block text-sm font-medium mb-1 text-left">
                      {/* Asset */}
                      {getLocale("asset")}
                    </label>
                    <div
                      onClick={() => setCoinFilter(true)}
                      className={`w-full  border ${
                        error?.coin ? "border-red-300" : "border-gray-300"
                      } rounded-lg p-2  flex justify-between items-center cursor-pointer h-[45px]`}
                    >
                      <p>
                        {p2pAddPayload?.coin
                          ? p2pAddPayload?.coin?.coin?.symbol
                          : "Select"}
                      </p>
                      <IconCaretDownFilled size={15} opacity={0.5} />
                    </div>
                    {error?.coin && (
                      <p className=" text-[10px] text-red-500 text-left">
                        {error?.coin}
                      </p>
                    )}
                  </div>
                  <div className="mb-4 w-1/2">
                    <label className="block text-sm font-medium mb-1 text-left">
                      {/* With fiat */}
                      {getLocale("withFiat")}
                    </label>
                    <button
                      disabled={!p2pAddPayload?.coin}
                      onClick={() => setCurrFilter(true)}
                      className="w-full border border-gray-300 rounded-lg p-2  flex justify-between items-center h-[45px] disabled:bg-bgGray disabled:opacity-50"
                    >
                      <p>
                        {p2pAddPayload?.fiatCurrency
                          ? p2pAddPayload?.fiatCurrency?.fiatName
                          : "Select"}
                      </p>

                      <IconCaretDownFilled size={15} opacity={0.5} />
                    </button>
                    {error?.fiatCurrency && (
                      <p className=" text-[10px] text-red-500 text-left">
                        {error?.fiatCurrency}
                      </p>
                    )}
                  </div>
                </div>
                <div className=" flex justify-between w-full gap-1 mt-3">
                  <div className="mb-4 w-full">
                    <label className="block text-sm font-medium mb-1 text-left">
                      {/* Fixed */}
                      {getLocale("fixed")}
                    </label>
                    <div
                      className={`w-full  border ${
                        error?.price ? "border-red-300" : "border-gray-300"
                      } rounded-lg p-2  h-[45px] flex justify-between items-center `}
                    >
                      <IconMinus
                        onClick={() => {
                          if (p2pAddPayload?.price >= 0) {
                            dispatch(
                              actions.setP2PPaylaod({
                                ...p2pAddPayload,
                                price:
                                  p2pAddPayload?.price - 1 < 0
                                    ? 0
                                    : Number(p2pAddPayload?.price - 1),
                              })
                            );
                          }
                        }}
                        className=" cursor-pointer"
                      />
                      <input
                        disabled={!p2pAddPayload?.fiatCurrency}
                        value={p2pAddPayload?.price ?? ""}
                        onChange={(e) => {
                          dispatch(
                            actions.setP2PPaylaod({
                              ...p2pAddPayload,
                              price: e.target.value,
                            })
                          );
                          if (
                            e.target.value >
                            p2pAddPayload?.fiatCurrency?.maxP2PAmount
                          ) {
                            setError({
                              ...error,
                              price:
                                "Price should be less than highest ad price",
                            });
                          } else if (
                            e.target.value <
                            p2pAddPayload?.fiatCurrency?.minP2PAmount
                          ) {
                            setError({
                              ...error,
                              price: "Price should be greater than min limit",
                            });
                          } else {
                            setError({ ...error, price: "" });
                          }
                        }}
                        className=" w-1/2 h-full outline-none border-none text-center disabled:bg-white"
                        type="number"
                        name=""
                        id=""
                      />
                      <IconPlus
                        onClick={() => {
                          dispatch(
                            actions.setP2PPaylaod({
                              ...p2pAddPayload,
                              price: p2pAddPayload?.price + 1,
                            })
                          );
                        }}
                        className=" cursor-pointer"
                      />
                    </div>
                    {error?.price && (
                      <p className=" text-[10px] text-red-500 text-left">
                        {error?.price}
                      </p>
                    )}
                    <p
                      className={`${
                        error?.price ? "text-red-500" : "text-textPrime"
                      } tracking-[-0.2px] leading-[145%] text-[10px] text-left`}
                    >
                      {/* Price range */}
                      {getLocale("priceRange")}
                      <span>
                        {` `}
                        <NumericFormat
                          value={p2pAddPayload?.fiatCurrency?.minP2PAmount}
                          displayType="text"
                          thousandSeparator
                        />{" "}
                        -{" "}
                        {isActive ? (
                          <NumericFormat
                            value={p2pAddPayload?.fiatCurrency?.maxP2PAmount}
                            displayType="text"
                            thousandSeparator
                          />
                        ) : (
                          <NumericFormat
                            value={p2pAddPayload?.fiatCurrency?.minP2PAmount}
                            displayType="text"
                            thousandSeparator
                          />
                        )}
                      </span>
                    </p>
                  </div>
                </div>
                <div className=" w-full gap-1 mt-3">
                  <p className="text-textPrime tracking-[-0.2px] leading-[145%] text-[14px] font-semibold text-left">
                    {/* Your Price */}
                    {getLocale("yourPrice")}
                    <span className=" text-[18px]">
                      {" "}
                      ₹{p2pAddPayload?.price ?? ""}
                    </span>
                  </p>
                  <p className="text-textPrime tracking-[-0.2px] flex items-center leading-[145%] text-[14px] font-semibold text-left">
                    {p2pAddPayload?.type === "BUY" ? "Highest" : "Lowest"} Ad
                    {/* price */}
                    {getLocale("price")}{" "}
                    <IconInfoCircle className=" mt-[4px] ml-0.5" size={12} />
                    <span className=" text-[18px] ml-1">
                      ₹
                      {p2pAddPayload?.type === "BUY" ? (
                        <NumericFormat
                          value={p2pAddPayload?.fiatCurrency?.maxP2PAmount ?? 0}
                          displayType="text"
                          thousandSeparator
                        />
                      ) : (
                        <NumericFormat
                          value={p2pAddPayload?.fiatCurrency?.minP2PAmount ?? 0}
                          displayType="text"
                          thousandSeparator
                        />
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <button
                onClick={() => {
                  const error = step1Error(p2pAddPayload);
                  setError({ ...error.errors });
                  if (error.isvalid) {
                    nextStep();
                  }
                }}
                className=" mt-8 w-full text-clip bg-bgBlue text-textWhite  h-[45px] rounded-md tracking-[0.2px] leading-[140%]"
              >
                {/* Next */}
                {getLocale("next")}
              </button>
            </div>
          )}
          {p2pFormStep === 2 && (
            <div className="transition-opacity duration-500 flex flex-col justify-between">
              <div>
                <div className=" flex justify-between w-full gap-1 mt-3">
                  <div className="mb-4 w-full">
                    <label className="block text-sm font-medium mb-1 text-left">
                      {/* Total amount */}
                      {getLocale("totalAmount")}
                    </label>
                    <div
                      className={` w-full  border ${
                        error.amount ? "border-red-500" : "border-gray-300"
                      } rounded-lg p-2  h-[45px] flex justify-between items-center `}
                    >
                      <input
                        value={p2pAddPayload?.amount}
                        onChange={(e) =>
                          dispatch(
                            actions.setP2PPaylaod({
                              ...p2pAddPayload,
                              amount: e.target.value,
                            })
                          )
                        }
                        className={`w-full h-full outline-none border-none text-left " placeholder="Enter total amount `}
                        type="number"
                        name=""
                        id=""
                      />
                      <div className=" flex items-center gap-2">
                        {p2pAddPayload?.coin
                          ? p2pAddPayload?.coin?.coin?.symbol
                          : "USDT"}
                        <span className=" text-textPrime font-bold">ALL</span>
                      </div>
                    </div>
                    <div className=" flex justify-between items-center">
                      {
                        <p className=" text-[10px] text-red-500 text-left">
                          {error?.amount}
                        </p>
                      }
                      <div>
                        <p className="text-textPrime tracking-[-0.2px] leading-[145%] text-[10px] text-right pr-1">
                          =
                          <span>
                            <NumericFormat
                              fixedDecimalScale={true}
                              decimalScale={2}
                              value={Number(
                                p2pAddPayload?.amount *
                                  Number(p2pAddPayload?.price)
                              )}
                              displayType="text"
                              thousandSeparator
                            />
                            {` `} {p2pAddPayload?.fiatCurrency?.fiatName ?? ""}
                          </span>
                        </p>
                        {p2pAddPayload?.type === "SELL" && (
                          <p className="text-textPrime tracking-[-0.2px] leading-[145%] text-[10px] text-right pr-1">
                            Avbl {``}= {` `}
                            <span>
                              <NumericFormat
                                fixedDecimalScale={true}
                                decimalScale={2}
                                value={p2pAddPayload?.coin?.userFundingAmount}
                                displayType="text"
                                thousandSeparator
                              />
                              {` `} {p2pAddPayload?.coin?.coin?.symbol ?? ""}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full gap-1 pb-5 border-b">
                  <label className="block text-sm font-medium mb-1 text-left">
                    {/* Order Limit */}
                    {getLocale("orderLimit")}
                  </label>
                  <div className="flex justify-between w-full gap-1">
                    <div className="w-1/2">
                      <div
                        className={`w-full  border ${
                          error.minLimit ? "border-red-500" : "border-gray-300"
                        } rounded-lg p-2  flex justify-between items-center cursor-pointer h-[45px]`}
                      >
                        <input
                          onChange={(e) => {
                            dispatch(
                              actions.setP2PPaylaod({
                                ...p2pAddPayload,
                                minLimit: e.target.value,
                              })
                            );
                          }}
                          value={p2pAddPayload?.minLimit}
                          type="number"
                          name=""
                          id=""
                          className="w-[80%] h-full outline-none border-none"
                        />
                        <span>
                          {selectedCurrency
                            ? selectedCurrency?.fiatName
                            : "INR"}
                        </span>
                      </div>
                      {error?.minLimit && (
                        <p className=" text-[10px] text-red-500 text-left">
                          {error?.minLimit}
                        </p>
                      )}
                      <p className="text-textPrime tracking-[-0.2px] leading-[145%] text-[10px] text-left">
                        =
                        {formatValue(
                          Number(p2pAddPayload?.minLimit ?? 0) /
                            Number(p2pAddPayload?.price ?? 0)
                        )}
                        {selectedCoin ? selectedCoin?.coin?.symbol : "USDT"}
                      </p>
                    </div>
                    <div className="w-1/2">
                      <div
                        className={`"w-full  border ${
                          error.maxLimit ? "text-red-500" : "border-gray-300"
                        } rounded-lg p-2  flex justify-between items-center cursor-pointer h-[45px]`}
                      >
                        <input
                          onChange={(e) => {
                            dispatch(
                              actions.setP2PPaylaod({
                                ...p2pAddPayload,
                                maxLimit: e.target.value,
                              })
                            );
                          }}
                          value={p2pAddPayload?.maxLimit}
                          type="number"
                          name=""
                          id=""
                          className="w-[80%] h-full outline-none border-none"
                        />
                        <span>
                          {selectedCurrency
                            ? selectedCurrency?.fiatName
                            : "INR"}
                        </span>
                      </div>
                      {error?.maxLimit && (
                        <p className=" text-[10px] text-red-500 text-left">
                          {error?.maxLimit}
                        </p>
                      )}
                      <p className="text-textPrime tracking-[-0.2px] leading-[145%] text-[10px] text-left">
                        =
                        {formatValue(
                          Number(p2pAddPayload?.maxLimit ?? 0) /
                            Number(p2pAddPayload?.price ?? 0)
                        )}
                        {selectedCoin ? selectedCoin?.coin?.symbol : "USDT"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" mt-5">
                  <div className="flex justify-between items-center w-full">
                    <div>
                      <p
                        className={`tracking-[-0.2px] leading-[145%] text-[12px] text-left ${
                          error.paymentMethods
                            ? "text-red-500"
                            : "text-textPrime"
                        }`}
                      >
                        {/* Payment Method */}
                        {getLocale("paymentMethod")}
                      </p>
                      <p
                        className={`tracking-[-0.2px] leading-[145%] text-[10px] text-left ${
                          error.paymentMethods
                            ? "text-red-500"
                            : "text-textPrime"
                        }`}
                      >
                        {/* Select upto 5 methods */}
                        {getLocale("selectUpto")}
                      </p>
                    </div>
                    <button
                      onClick={() => setSelectPaymethodSlider(true)}
                      className=" flex gap-1 items-center px-2 py-1 bg-bgBlue text-textWhite rounded-md"
                    >
                      <IconPlus size={15} className=" mt-0.5 font-bold" />
                      <span>
                        {/* Add */}
                        {getLocale("add")}
                      </span>
                    </button>
                  </div>
                  <div className=" w-full flex flex-wrap gap-2 items-start justify-start mt-5">
                    {data
                      ?.filter((dt) =>
                        p2pAddPayload?.paymentMethods?.includes(dt?._id)
                      )
                      ?.map((item, index) => (
                        <div
                          key={item?.id}
                          className="text-[#111] text-left text-base flex h-full items-center justify-between gap-2 border px-2 rounded-sm cursor-pointer w-[100px] text-clip  "
                        >
                          <IconX
                            onClick={() => {
                              console.log("item: ", item);
                              const method = [...p2pAddPayload?.paymentMethods];
                              const filtered = method?.filter(
                                (val) => val != item?._id
                              );
                              console.log("filtered: ", filtered);

                              dispatch(
                                actions.setP2PPaylaod({
                                  ...p2pAddPayload,
                                  paymentMethods: filtered,
                                })
                              );
                            }}
                            size={15}
                            className=" cursor-pointer text-textPrime"
                          />
                          {/* <div className={`${item.color} w-[2px] h-1.5`}></div> */}
                          <p className=" text-[10px]">{item?.type}</p>
                        </div>
                      ))}
                  </div>
                </div>
                <div className=" mt-5">
                  <FormControl fullWidth hiddenLabel>
                    <label
                      className="text-sm   gap-1 font-medium mb-1 text-left flex items-center"
                      htmlFor=""
                    >
                      {/* Payment Time Limit */}
                      {getLocale("paymentTimeLimit")}{" "}
                      <IconInfoCircle
                        size={15}
                        className=" mt-0.5 cursor-pointer"
                      />{" "}
                    </label>
                    <Select
                      value={p2pAddPayload?.paymentTimeLimit}
                      onChange={(e) => {
                        dispatch(
                          actions.setP2PPaylaod({
                            ...p2pAddPayload,
                            paymentTimeLimit: e.target.value,
                          })
                        );
                      }}
                      inputProps={{
                        style: {
                          textAlign: "left",
                        },
                      }}
                      sx={{
                        borderColor: "red",
                        height: "45px",
                        width: "100%",
                        textAlign: "left",
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: error?.paymentTimeLimit ? "red" : "#ccc",
                        },
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={age}
                      label=""
                      // onChange={handleChange}
                    >
                      <MenuItem value={10}>10 Minute</MenuItem>
                      <MenuItem value={15}>15 Minute</MenuItem>
                      <MenuItem value={20}>20 Minute</MenuItem>
                    </Select>
                  </FormControl>
                  {error?.paymentTimeLimit && (
                    <p className=" text-[10px] text-red-500 text-left">
                      {error?.paymentTimeLimit}
                    </p>
                  )}
                </div>
                <div className=" mt-5 flex justify-between items-center border-t ">
                  <p
                    className="gap-1 font-medium flex items-center text-textPrime tracking-[-0.2px] leading-[145%] text-[12px] text-left mt-5"
                    htmlFor=""
                  >
                    {/* Recieved fee */}
                    {getLocale("receivedFee")}{" "}
                    <IconInfoCircle
                      size={15}
                      className=" mt-0.5 cursor-pointer"
                    />
                    <span className=" mt-0.5">-- USDT</span>
                  </p>
                </div>
              </div>
              <button
                onClick={() => {
                  const error = step2Error(p2pAddPayload);
                  setError({ ...error.errors });
                  if (error.isvalid) {
                    nextStep();
                  }
                }}
                className="w-full text-clip bg-bgBlue text-textWhite  h-[45px] rounded-md tracking-[0.2px] leading-[140%]"
              >
                {/* Next */}
                {getLocale("next")}
              </button>
            </div>
          )}

          {/* Step 3: Price Type Selection */}
          {p2pFormStep === 3 && (
            <div className="transition-opacity duration-500 flex flex-col justify-between">
              {/* <div className="w-full ">
                                <label className="block text-sm font-medium mb-1 text-left">
                                    Terms tags (optional)
                                    <p className=" text-[10px] p-0">Select upto 3</p>
                                </label>
                                <div onClick={() => setisTags(true)} className="w-full  border border-gray-300 rounded-lg p-2  flex justify-between items-center cursor-pointer min-h-[45px] gap-5">
                                    {selectedTag?.length > 0 ? <div className=" flex flex-wrap gap-2 items-start justify-start">
                                        {
                                            selectedTag?.map((item, index) => (
                                                <div onClick={(e) => e.stopPropagation()} key={item?.id} className="text-[#111] text-left text-base mr-auto flex h-full items-center gap-0.5 border px-2 rounded-sm cursor-pointer ">
                                                    <IconX onClick={() => setSelectedTag(prev => prev?.filter(val => val?.id !== item?.id))} size={20} className=" cursor-pointer text-textPrime" />
                                                    <div className={`${item.color} w-[2px] h-1.5`}></div>
                                                    <p className=" text-[10px]">{item.name}</p>
                                                </div>
                                            ))
                                        }
                                    </div> : <p>Add tags</p>}
                                    <IconCaretDownFilled size={15} opacity={0.5} />
                                </div>
                            </div> */}
              <div className=" mt-5 w-full">
                <label className="block text-sm font-medium mb-1 text-left">
                  {/* Memo */}
                  {getLocale("memo")}
                </label>

                <textarea
                  value={p2pAddPayload?.remarks}
                  onChange={(e) => {
                    dispatch(
                      actions.setP2PPaylaod({
                        ...p2pAddPayload,
                        remarks: e.target.value,
                      })
                    );
                  }}
                  className={`w-full  border ${
                    error.remarks ? "border-red-500" : "border-gray-300"
                  } rounded-lg p-2  flex justify-between items-center outline-none resize-none min-h-[120px]`}
                  name=""
                  id=""
                  placeholder="Memo"
                ></textarea>
                {error?.remarks && (
                  <p className=" text-[10px] text-red-500 text-left">
                    {error?.remarks}
                  </p>
                )}
              </div>
              <button
                onClick={handleSubmit}
                className=" mt-5 w-full text-clip bg-bgBlue text-textWhite  h-[45px] rounded-md tracking-[0.2px] leading-[140%]"
              >
                {/* Next */}
                {getLocale("next")}
              </button>
            </div>
          )}
        </div>
      </div>

      <Tags
        currFilter={isTags}
        setCurrFilter={setisTags}
        selectedCoin={selectedTag}
        setSelectedCoin={setSelectedTag}
      />
      <Region
        currFilter={isregion}
        setCurrFilter={setIsregion}
        selectedCoin={selectedregion}
        setSelectedCoin={setSelectedRegion}
      />
      <SelectPayMethods
        currFilter={selectPaymethodSlider}
        setCurrFilter={setSelectPaymethodSlider}
        data={data}
      />
      <CoinSlider currFilter={coinFilter} setCurrFilter={setCoinFilter} />
      <CurrencySlider currFilter={currFilter} setCurrFilter={setCurrFilter} />
    </div>
  );
};

export default PostAdForm;
