import request from "./request";

// auth
export const login = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/user/auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        resolve(e.response.data);
      });
  });
};

export const register = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/user/auth/register", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        resolve(e.response.data);
      });
  });
};

export const resendOtp = (data) => {
  console.log(data);
  return new Promise(async (resolve, reject) => {
    await request
      .patch("auth/resend/email", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const verifyOtp = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("auth/verifyEmail", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const verifyMobile = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("/user/auth/mobile/verify", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const resendMobile = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("/user/auth/mobile/resend", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addMobile = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/user/auth/add-number", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addEmail = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/user/auth/add-email", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const resendEmail = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("/user/auth/email/resend", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const verifyEmail = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("/user/auth/email/verify", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const googleLogin = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/user/auth/google/login", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const userSendCode = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/user/auth/api/register", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const userRegEmail = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("/user/auth/api/email/verify", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const userRegMobile = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("/user/auth/api/mobile/verify", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const passwordCreate = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/user/auth/password", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        resolve(e.response.data);
      });
  });
};
export const forgotPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/user/auth/forgot/password", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const changePassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/user/auth/password", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getUserProfile = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/user/profile")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getUserProfileUpdate = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/user/profile", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addIdProof = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/user/profile/kyc/id-proof", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addKycInfo = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("user/profile/kyc/info", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addKycNation = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("user/profile/kyc/nationality", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addKycAddress = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("user/profile/kyc/address", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addWhyUsing = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("user/profile/why-using", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addAmount = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("user/profile/amount", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const uploadDcoument = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("user/profile/kyc/document", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Firebase Token
export const userFireBaseToken = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/user/notification", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Firebase remove Token
export const userFireBaseRemoveToken = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete("/user/notification")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Buy Coins
export const getAllCoins = (page, ispagination, sizePerPage) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(
        `user/coin?page=${page}&sizePerPage=${sizePerPage}&pagination=${ispagination}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getAllP2PSupportedCoins = (
  page,
  ispagination,
  sizePerPage,
  isSell
) => {
  return new Promise(async (resolve, reject) => {
    const url = isSell
      ? `admin/P2PSupportCoin/sell?page=${page}&sizePerPage=${sizePerPage}&pagination=${ispagination}`
      : `admin/P2PSupportCoin?page=${page}&sizePerPage=${sizePerPage}&pagination=${ispagination}`;
    await request
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const buyCoin = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/coinbuysell`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// currency

export const getAllCurrency = (page, ispagination, sizePerPage) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(
        `admin/currency?page=${page}&sizePerPage=${sizePerPage}&pagination=${ispagination}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Get Payment methods from admin
export const getAllPaymethods = (page, ispagination, sizePerPage, params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(
        `admin/paymentMethod?page=${page}&sizePerPage=${sizePerPage}&pagination=${ispagination}`,
        { params }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getPaymethodById = (Id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/paymentMethod/${Id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// User adding payment
export const addPayMethodByUser = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/userPaymentMethod`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editPayMethodByUser = (data, Id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`user/userPaymentMethod/${Id}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deletePayMethodByUser = (Id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`user/userPaymentMethod/${Id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getAllPayMethodByUser = (page, ispagination, sizePerPage) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(
        `user/userPaymentMethod?page=${page}&sizePerPage=${sizePerPage}&pagination=${ispagination}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getPayMethodAddedByUser = (Id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/userPaymentMethod/${Id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// p2p add
export const getOrders = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("user/coinbuysell", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getOrdersById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("user/coinbuysell/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addP2pOrder = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/userP2POrders`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteP2pOrder = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/userP2POrders/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const userP2PAds = (page, size, type, coin) => {
  return new Promise(async (resolve, reject) => {
    let url = `user/userP2PAds?page=${page}&sizePerPage=${size}&pagination=false&type=${type}`;
    if (coin) {
      url = `user/userP2PAds?page=${page}&sizePerPage=${size}&pagination=false&type=${type}&coin=${coin}`;
    }
    await request
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getAllP2pOrders = (page, ispagination, sizePerPage, params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(
        `user/userP2POrders?page=${page}&sizePerPage=${sizePerPage}&pagination=${ispagination}`,
        { params }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getP2PById = (Id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/userP2POrders/${Id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const placeP2POrder = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/userP2POrderTrx`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const verifyP2POrder = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/userP2POrderTrx/confirmation/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const confirmP2POrder = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/userP2POrderTrx/receiverConfirmation/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const cancelP2POrder = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`user/userP2POrderTrx/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getAllplacedP2POrder = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/userP2POrderTrx`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getp2pTrxById = (ID) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/userP2POrderTrx/${ID}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getAllplacedP2POrderForUser = (params, userId) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/userP2POrderTrx/user/${userId}`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getWallet = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/wallet`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// TRADING_PAIR

export const getTradingPair = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/trading-pair`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// ORDER
export const placePairOrder = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/order`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const rateOrder = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/p2pOrderRating`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getTradeHistory = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/orderTransaction`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getGraph = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/orderTransaction/graph`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getTradingPairOrder = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/order`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const cancleOrder = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`user/order/${id}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getPairOrderById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/order/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const walletTransfer = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/walletTransfer`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Chats for order
export const createChat = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/chat`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getChats = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/chat?pagination=false`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getChatById = (Id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/chat/${Id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteChat = (Id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`user/chat/${Id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const addMessage = (orderId, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/chat/message/${orderId}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getOrderBook = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/user/orderTransaction/price`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getAllTransaction = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/user/orderTransaction/all`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getTransactionById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/user/orderTransaction/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getConvertCoin = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/user/walletTransfer/transaction`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getCoinOrderData = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/user/orderTransaction/coin`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getCoinNetwork = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/admin/coinNetwork`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getWalletAddress = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/user/user-wallet/trx/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const postWithdraw = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`/user/user-wallet/trxWithdrawRequest`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getTrWallet = (params, id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/user/user-wallet/transaction/${id}`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getWalletTr = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/user/walletTransfer/transaction`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getNotification = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/user/notification`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const updateMode = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`/user/profile/p2p/${data}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const verify2FA = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`/user/auth/2fa/verify`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const generate2FA = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`/user/auth/2fa/generate`, )
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
