import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import sideLogo from "../../Assets/image/sideLogo.png";
import bell from "../../Assets/icons/Bell.svg";
import cart from "../../Assets/icons/Cart.svg";
import deposit from "../../Assets/icons/deposit.svg";
import withdraw from "../../Assets/icons/withdraw.svg";
import info from "../../Assets/icons/Info.svg";
import p2p from "../../Assets/icons/p2p.svg";
import wallet from "../../Assets/icons/wallet.svg";
import tutorial from "../../Assets/icons/tutorial.svg";
import history from "../../Assets/icons/History.svg";
import { getLocale } from "../../constants/language";

// FIREBASE
import { onMessage } from "firebase/messaging";
import { messaging } from "../../firebase";
import { useSelector, useDispatch } from "react-redux";
import { ROUTES } from "../../constants/routes";
import actions from "../../redux/user/actions";
import { useTranslation } from "react-i18next";

const Layout = ({ children }) => {
  const { user, language, mode } = useSelector((state) =>
    state?.user ? state?.user : state
  );
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
  // Sidebar content for the Drawer
  const sidebarContent = (
    <Box
      sx={{ width: 250, marginInline: "auto" }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <List>
        {["Home", "About", "Services", "Contact"].map((text) => (
          <ListItem button key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  useEffect(() => {
    onMessage(messaging, (payload) => {
      if (payload) {
        // handleNotification();
      }
    });
  }, []);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className=" max-w-[425px]  mx-auto min-h-[100vh] h-full flex flex-col justify-between relative overflow-hidden">
      <div
        className={`flex-1 ${
          location.pathname === "/" ||
          location.pathname === "/register" ||
          location.pathname !== "/home" ||
          location.pathname === "/login" ||
          location.pathname === "/securityInfo" ||
          location.pathname === "/securityId" ||
          location.pathname === "/sPhotoId" ||
          location.pathname === "/personalInfo" ||
          location.pathname === "/citizenship" ||
          location.pathname === "/address" ||
          location.pathname === "/purpose" ||
          location.pathname === "/expectations" ||
          location.pathname === "/successRegister" ||
          location.pathname === "/onboarding" ||
          location.pathname === "/onboarding1" ||
          location.pathname === "/security" ||
          location.pathname === "/securitySteps" ||
          location.pathname === "/securityEmail" ||
          location.pathname === "/twoFA" ||
          location.pathname === "/confirmTwoFa" ||
          location.pathname === "/Recieve" ||
          location.pathname === "/selectAssets" ||
          location.pathname === "/addPaymentMethod" ||
          location.pathname === "/buy" ||
          location.pathname === "/orderSummary" ||
          location.pathname === "/buySent" ||
          location.pathname === "/orderStatus" ||
          location.pathname === "/convert" ||
          location.pathname === "/recieve" ||
          location.pathname === "/confirmTwoFaMob" ||
          location.pathname === "/emailVerify" ||
          location.pathname === "/forgotPassword" ||
          location.pathname === "/changePassword" ||
          // location.pathname === "/P2PHOME" ||
          location.pathname === "/P2PAddNew" ||
          // location.pathname === "/paymentMethods" ||
          location.pathname === "/recomended" ||
          location.pathname === "/addPayMethod" ||
          location.pathname === "/editPayMethod" ||
          location.pathname === "/buyP2P" ||
          location.pathname === "/verifyOrder" ||
          location.pathname === "/verifyOrderToSell" ||
          location.pathname === "/selectMethod" ||
          location.pathname === "/selectpayforlite" ||
          location.pathname === "/liteBuy" ||
          location.pathname !== ROUTES.LITEBUYENTERAMOUNT ||
          location.pathname !== ROUTES.LITESELLENTERAMOUNT ||
          location.pathname !== ROUTES.LITESETTINGS ||
          location.pathname !== ROUTES.P2PLITESETTINGS ||
          location.pathname !== ROUTES.P2PLITENOTIFICATIONSETTINGS ||
          // location.pathname === "/myAdds" ||
          location.pathname === "/faceId"
            ? "pb-0"
            : "pb-[80px]"
        }
        ${location.pathname === "/home" && "pb-[80px]"}
        `}
      >
        {children}
      </div>
      {location.pathname !== "/" &&
        location.pathname !== "/register" &&
        location.pathname !== "/securityInfo" &&
        location.pathname !== "/securityId" &&
        location.pathname !== "/sPhotoId" &&
        location.pathname !== "/faceId" &&
        location.pathname !== "/personalInfo" &&
        location.pathname !== "/citizenship" &&
        location.pathname !== "/purpose" &&
        location.pathname !== "/address" &&
        location.pathname !== "/expectations" &&
        location.pathname !== "/successRegister" &&
        location.pathname !== "/onboarding" &&
        location.pathname !== "/onboarding1" &&
        location.pathname !== "/security" &&
        location.pathname !== "/securitySteps" &&
        location.pathname !== "/securityEmail" &&
        location.pathname !== "/twoFA" &&
        location.pathname !== "/confirmTwoFa" &&
        location.pathname !== "/confirmTwoFaMob" &&
        location.pathname !== "/Recieve" &&
        location.pathname !== "/selectAssets" &&
        location.pathname !== "/buy" &&
        location.pathname !== "/addPaymentMethod" &&
        location.pathname !== "/orderSummary" &&
        location.pathname !== "/buySent" &&
        location.pathname !== "/orderStatus" &&
        location.pathname !== "/convert" &&
        location.pathname !== "/recieve" &&
        location.pathname !== "/emailVerify" &&
        location.pathname !== "/forgotPassword" &&
        location.pathname !== "/changePassword" &&
        // location.pathname !== "/P2PHOME" &&
        location.pathname !== "/P2PAddNew" &&
        // location.pathname !== "/paymentMethods" &&
        location.pathname !== "/recomended" &&
        location.pathname !== "/addPayMethod" &&
        location.pathname !== "/editPayMethod" &&
        location.pathname !== "/buyP2P" &&
        location.pathname !== "/verifyOrder" &&
        location.pathname !== "/verifyOrderToSell" &&
        location.pathname !== "/selectMethod" &&
        location.pathname !== ROUTES.LITEBUYENTERAMOUNT &&
        location.pathname !== ROUTES.LITESELLENTERAMOUNT &&
        location.pathname !== ROUTES.SELECTPAYMENTFORLITE &&
        location.pathname !== ROUTES.LITEBUY &&
        location.pathname !== ROUTES.LITESETTINGS &&
        location.pathname !== ROUTES.P2PLITESETTINGS &&
        location.pathname !== ROUTES.P2PLITENOTIFICATIONSETTINGS &&
        // location.pathname !== "/myAdds" &&
        location.pathname !== "/login" && (
          <Navbar
            toggleDrawer={toggleDrawer}
            isP2P={
              location.pathname === "/P2PHOME" ||
              location.pathname === "/paymentMethods" ||
              location.pathname === "/myAdds" ||
              location.pathname === "/verifyOrderToSell" ||
              location.pathname === "/P2POrder"
            }
          />
        )}
      <div
        className={`customeTrinsition w-full z-20 h-[100vh] absolute flex justify-end right-0 ${
          isDrawerOpen ? "translate-x-[100%]" : ""
        } overflow-hidden`}
      >
        {" "}
        <div
          onClick={() => toggleDrawer()}
          className={`customeTrinsition w-full z-20 h-[100vh] fixed  flex justify-end ${
            isDrawerOpen ? "translate-x-[100%]" : ""
          } overflow-hidden`}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className=" bg-white h-full w-[180px] shadow-lg flex flex-col justify-between border-l border-borderGray"
          >
            <div>
              <div className=" flex justify-center pt-5 flex-col items-center">
                <img className="w-[94px] h-[38px]" src={sideLogo} alt="Logo" />
              </div>
              {/* <label className="inline-flex items-center cursor-pointer gap-2 mt-5">
                <span className="ms-3 -mt-1 text-sm font-medium text-gray-900 dark:text-gray-300">
                  {getLocale("traderMode")}
                </span>
                <input type="checkbox" value="" className="sr-only peer" />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
              </label> */}
              <div className="flex flex-col justify-end items-start mt-8 px-4">
              <label className="inline-flex items-center cursor-pointer gap-2 my-3">
                <span className="ms-3 -mt-1 text-sm font-medium text-gray-900">
                  ENG
                </span>
                <input
                  type="checkbox"
                  checked={language === "sp"}
                  onChange={() => {
                    changeLanguage(language === "sp" ? "en" : "es");
                    dispatch(
                      actions.setLangauge(language === "sp" ? "en" : "sp")
                    );
                    // window.location.reload();
                  }}
                  className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
                <span className="ms-3 -mt-1 text-sm font-medium text-gray-900">
                  ESP
                </span>
              </label>
                {/* <Link
                  to={ROUTES.NOTIFICATIONS}
                  onClick={() => setIsDrawerOpen(true)}
                  className=" flex justify-between items-center gap-4 text-[11px] text-[#111111] font-normal space-x-[0.02px]"
                >
                  {" "}
                  <img src={bell} alt="" />{" "}
                  {getLocale("notifications")}
                </Link> */}
                <Link
                  to={ROUTES.SELECT_ASSETS_TO_BUY}
                  onClick={() => setIsDrawerOpen(true)}
                  className="mt-3 flex justify-between items-center gap-4 text-[11px] text-[#111111] font-normal space-x-[0.02px]"
                >
                  {" "}
                  {/* Buy  */}
                  <img src={cart} alt="" />{" "}
                  {getLocale("buy")}
                </Link>
                <Link
                  to={`${ROUTES.WALLET}?funding`}
                  className=" flex  mt-6 justify-between items-center gap-4 text-[11px] text-[#111111] font-normal space-x-[0.02px]"
                >
                  {" "}
                  {/* Deposit  */}
                  <img src={deposit} alt="" />{" "}
                  {getLocale("deposit")}
                </Link>
                <Link
                  to={`${ROUTES.WALLET}?funding`}
                  onClick={() => setIsDrawerOpen(true)}
                  className=" flex mt-6 justify-between items-center gap-4 text-[11px] text-[#111111] font-normal space-x-[0.02px]"
                >
                  {" "}
                  {/* Withdraw  */}
                  <img src={withdraw} alt="" />{" "}
                  {getLocale("withdraw")}
                </Link>
                {/* <Link
                  to={ROUTES.REFER}
                  onClick={() => setIsDrawerOpen(true)}
                  className=" flex mt-6 justify-between items-center gap-4 text-[11px] text-[#111111] font-normal space-x-[0.02px]"
                >
                  {" "}
                  Requests <img src={info} alt="" />{" "}
                </Link> */}
                {user?.p2p?.mode === "ORDER" && (
                  <Link
                    to={ROUTES.P2PHOME}
                    onClick={() => setIsDrawerOpen(true)}
                    className=" flex mt-6 justify-between items-center gap-4 text-[11px] text-[#111111] font-normal space-x-[0.02px]"
                  >
                   
                    <img src={p2p} alt="" />{" "}
                    P2P 
                  </Link>
                )}
                  <Link
                    to={ROUTES.MARKET}
                    onClick={() => setIsDrawerOpen(true)}
                    className=" flex mt-6 justify-between items-center gap-4 text-[11px] text-[#111111] font-normal space-x-[0.02px]"
                  >
                  
                    <img src={p2p} alt="" />{" "}
                    Spot 
                  </Link>
                {/* <Link
                to={"/myAdds"}
                onClick={() => setIsDrawerOpen(true)}
                className=" flex mt-6 justify-between items-center gap-4 text-[11px] text-[#111111] font-normal space-x-[0.02px]"
              >
                {" "}
                My P2P Ads <img src={p2p} alt="" />{" "}
              </Link> */}
                <Link
                  to={ROUTES.WALLET}
                  onClick={() => setIsDrawerOpen(true)}
                  className=" flex mt-6 justify-between items-center gap-4 text-[11px] text-[#111111] font-normal space-x-[0.02px]"
                >
                  {/* My wallet  */}
                  <img src={wallet} alt="" />{" "}
                  {getLocale("myWallet")}
                </Link>
                {/* <Link className=" flex mt-6 justify-between items-center gap-4 text-[11px] text-[#111111] font-normal space-x-[0.02px]">
                  {getLocale("tutorial")}
                  <img src={tutorial} alt="" />{" "}
                </Link> */}
                <Link
                  to={ROUTES.COIN}
                  onClick={() => setIsDrawerOpen(true)}
                  className=" flex mt-6 justify-between items-center gap-4 text-[11px] text-[#111111] font-normal space-x-[0.02px]"
                >
                  {/* History */}
                  <img src={history} alt="" />{" "}
                  {getLocale("history")}
                </Link>
                <Link
                  to={ROUTES.PAIRCOIN}
                  onClick={() => setIsDrawerOpen(true)}
                  className=" flex mt-6 justify-between items-center gap-4 text-[11px] text-[#111111] font-normal space-x-[0.02px]"
                >
                  {/* My Orders */}
                  <img src={history} alt="" />{" "}
                  {getLocale("myOrders")}
                </Link>
              </div>
            
            </div>

            <div
              className=" flex w-full justify-center items-center pb-16 flex-col cursor-pointer"
              onClick={() => {
                setIsDrawerOpen(true);
                navigate(ROUTES.PROFILE);
              }}
            >
              <div className=" w-16 h-16 rounded-full border bg-black flex justify-center items-center text-[#fff] text-[]">
                {user?.firstName && user?.firstName?.charAt(0)}
                {user?.lastName && user?.lastName?.charAt(0)}
              </div>
              <p className=" text-[#111111] text-[11px] leading-[140%] tracking-[0.2px] ">
                {/* My account */}
                {getLocale("myAccount")}
              </p>
            </div>
          </div>
        </div>
        {/* <Drawer
      
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            width: 250,
            backgroundColor: "#fff",
          },
          position:"absolute",
          right: 0,
        }}
      >
        {sidebarContent}
      </Drawer> */}
      </div>
    </div>
  );
};

export default Layout;
