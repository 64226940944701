import { IconInfoCircle, IconReload, IconX } from "@tabler/icons-react";
import React from "react";

function PopUp({ children, title, setOpen, open, height, reload }) {
  return (
    <div
      className={` z-30 ${
        open ? `${height ? `h-[${height}%]` : "h-[86.5%]"}` : "h-0"
      } transition-all rounded-tl-xl rounded-tr-xl bottom-0 overflow-hidden w-full absolute bg-bgWhite left-0 shadow-2xl `}
    >
      <div className=" flex justify-between items-center py-2 px-4 ">
        <p className=" font-semibold text-[16px] text-textPrime tracking-[-0.5px] leading-[140%] flex items-center gap-1">
          {title}
          {title === "Payment Methods" && (
            <IconInfoCircle
              size={20}
              className=" text-textPrime cursor-pointer"
            />
          )}
        </p>
        {title !== "Select Coin" && title !== "Chat" && (
          <IconX
            onClick={() => setOpen(false)}
            size={25}
            className=" cursor-pointer text-textPrime"
          />
        )}
        {title === "Chat" && (
          <IconReload
            onClick={() => reload()}
            size={25}
            className=" cursor-pointer text-textPrime"
          />
        )}
      </div>
      {children}
    </div>
  );
}
export function PopUp2({ children, title, setOpen, open, height, reload }) {
  return (
    <div
      className={`z-30 ${
        open ? "h-[22%] border" : "h-0"
      } max-w-[95%] mx-auto transition-all rounded-tl-xl rounded-xl overflow-hidden w-full bg-[#bebdbd] shadow-2xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}
    >
      <div className="flex justify-between items-center py-2 px-4">
        <p className="font-semibold text-[16px] text-textPrime tracking-[-0.5px] leading-[140%] flex items-center gap-1">
          {title}
        </p>

        <IconX
          onClick={() => setOpen(false)}
          size={25}
          className="cursor-pointer text-textPrime"
        />
      </div>
      {children}
    </div>
  );
}

export default PopUp;
