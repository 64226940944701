import {
  IconArrowNarrowLeft,
  IconArrowRightToArc,
  IconChevronRight,
  IconHeadphonesFilled,
  IconSettings,
} from "@tabler/icons-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../Assets/image/favicon.png";
import { IconButton, Tooltip } from "@mui/material";
import { ROUTES } from "../../../constants/routes";
import {
  getUserProfile,
  updateMode,
  userFireBaseRemoveToken,
} from "../../../service/api";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/user/actions";

const LiteDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { firebaseToken, user } = useSelector((state) => state.user);
  console.log("%c Line:25 🥚 user", "color:#6ec1c2", user);
  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    if (firebaseToken) {
      FireBaseTokenRemove();
    } else {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(actions.setToken(null));
      dispatch(actions.setLogout(null));
    }
    navigate(ROUTES.HOME);
    dispatch(actions.setUser(null));
    dispatch(actions.setLoggedIn(false));
  };

  const FireBaseTokenRemove = () => {
    setLoading(true);
    userFireBaseRemoveToken().then((res) => {
      if (res?.status) {
        dispatch(actions.setToken(null));
        localStorage.clear();
        dispatch(actions.setLogout(null));
        sessionStorage.clear();
        navigate(ROUTES.HOME);
      }
    });
  };

  const handleMode = () => {
    if (loading) {
      return;
    }

    setLoading(true);

    updateMode(user?.p2p?.mode === "LITE" ? "ORDER" : "LITE")
      ?.then((res) => {
        if (res?.status) {
          getProfile();
        }
      })
      ?.finally(() => setLoading(false));
  };

  const getProfile = () => {
    getUserProfile()?.then((res) => {
      if (res?.status) {
        dispatch(actions.setUser(res?.data));
      }
    });
  };
  return (
    <div className="h-full min-h-[100vh] max-h-[100vh] overflow-y-auto relative justify-start bg-white font-sans pt-8 pb-3 flex flex-col">
      <div className=" px-4 flex justify-between items-center">
        <IconArrowNarrowLeft
          className="size-7 cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <h1 className="font-semibold leading-[145%] tracking-[-1px] text-[18px] text-[#111111] "></h1>
        <div className="flex items-center justify-center gap-2 ">
          <Tooltip>
            <IconButton >
              <IconHeadphonesFilled size={20} className="hidden"/>
            </IconButton>
          </Tooltip>
          <Tooltip onClick={() => navigate(ROUTES.LITESETTINGS)}>
            <IconButton>
              <IconSettings size={20} />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <div className="hidden bg-yellow-100 text-yellow-700 text-sm p-3 rounded-lg mt-4">
        <p>Your account has been logged in on another device.</p>
      </div>

      {/* User Info Section */}
      <div className=" flex items-center justify-between px-4 w-full mt-4 p-4 border rounded-lg">
        <div className="flex items-center">
          <img
            src={logo}
            alt="User Avatar"
            className="w-10 h-10 rounded-full border"
          />
          <div className="ml-4">
            <p className="font-semibold">{user?.firstName}{" "}{user?.lastName}</p>
            <div className="flex items-center space-x-2 text-sm">
              {/* <span className="text-gray-500">Regular</span> */}
             {user?.kycStatus==="APPROVED"? <span className="text-green-500 font-semibold">Verified</span>:<span className="text-red-500 font-semibold">Unverified</span>}
            </div>
          </div>
        </div>
        <IconChevronRight size={30} />
      </div>

      {/* Action Grid */}
      <div className=" px-4">
        <div className=" flex flex-wrap justify-start gap-4 mt-6">
          {[
            // { name: "Binance Wallet", icon: "💼" },
            // { name: "Deposit", icon: "💳" },
            // { name: "Convert", icon: "🔄" },
            // { name: "Referral", icon: "👥" },
            // { name: "Rewards Hub", icon: "🎁" },
            // { name: "Support", icon: "❓" },
            // { name: "Log Out", icon: "🔓" },
          ].map((item, index) => (
            <div
              key={index}
              onClick={() => {
                if (item?.name === "Log Out") {
                  handleLogout();
                }
              }}
              className="flex flex-col items-center justify-center p-1 px-4 rounded-lg cursor-pointer hover:bg-gray-100"
            >
              <div className="text-[20px]">{item.icon}</div>
              <p className="text-[12px] mt-2 text-center">{item.name}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="absolute bottom-[180px] w-full px-4 ">
        <div
          onClick={handleMode}
          className="mt-6 w-full p-4 border text-center hover:bg-[#ccc]/[0.5]  cursor-pointer text-gray-500 text-sm flex justify-between px-4 rounded-[20px]  "
        >
          <div className=" flex items-center gap-2 ">
            <img src={logo} className=" size-6" />
            <p>
              KMBIO{" "}
              <span className=" text-green-500 ">
                {user?.p2p?.mode === "LITE" ? "Pro" : "Lite"}
              </span>
            </p>
          </div>
          <IconChevronRight />
        </div>
      </div>
      <div className="absolute bottom-[100px] w-full px-4 ">
        <div
          // onClick={handleMode}
          onClick={() => {
              handleLogout();
          }}
          className="mt-6 w-full p-4 border text-center hover:bg-[#ccc]/[0.5]  cursor-pointer text-gray-500 text-sm flex justify-between px-4 rounded-[20px]  "
        >
          <div className=" flex items-center gap-2 ">
            <img src={logo} className=" size-6" />
            <p>
              KMBIO{" "}
              <span className=" text-red-500 ">
                Logout
                {/* {user?.p2p?.mode === "LITE" ? "Pro" : "Lite"} */}
              </span>
            </p>
          </div>
          <IconArrowRightToArc />
        </div>
      </div>
    </div>
  );
};

export default LiteDashboard;
