export function formatValue(value) {
  const numValue = Number(value);

  if (isNaN(numValue)) {
    return value;
  }

  if (numValue <= 0) {
    return numValue.toFixed(2);
  }

  return numValue > 0 ? numValue.toFixed(3) : numValue.toFixed(5);
}
