import axios from "axios";
import { SERVER_URL } from "../config/index";
import store from "../redux/store";
import actions from "../redux/user/actions";
const language = store.getState().user?.language || "en";

const request = axios.create({
  // baseURL: `https://kmbio-latest-a3ehfzcxc4h9dkau.japanwest-01.azurewebsites.net/${
  //   language === "sp" ? "es" : "en"
  // }`,
  baseURL: `https://kmbio-latest-a3ehfzcxc4h9dkau.japanwest-01.azurewebsites.net`,
  timeout: 1000000,
});
let requests = [];

request.interceptors.request.use(
  (config) => {
    if (store.getState().user?.token) {
      config.headers.Authorization = `${sessionStorage.getItem("token")}`;
    }
    store.dispatch(actions.setLoading(true));

    requests.push(config);
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
request.interceptors.response.use(
  function (response) {
    store.dispatch(actions.setLoading(false));
    return response;
  },
  function (error) {
    store.dispatch(actions.setLoading(false));

    if (error.response && error.response.status === 401) {
      window.location.href = `${window.location.origin}/login`;
      localStorage.clear();
      sessionStorage.removeItem("token");
      store.dispatch(actions.setUser(null));
      store.dispatch(actions.setLoggedIn(false));
    }

    return Promise.reject(error);
  }
);

export default request;
