import { IconArrowNarrowLeft } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getLocale } from "../../constants/language";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";

function Refer() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 pt-8 pb-20 flex flex-col">
        <div className=" flex justify-between items-center">
          <IconArrowNarrowLeft
            className="size-7 cursor-pointer"
            onClick={() => navigate(-1)}
          />
        </div>
        <div className="w-full py-[70px] border border-[#CFCFCF] rounded-md  flex justify-center items-center mt-4">
          {/* <img className=" object-cover" src={referBarCode} alt="barcode" /> */}
          <QRCode
            value={`${window.location.origin}/register/?refer=${user?.refral}`}
            size={150}
          />
        </div>
        <div className="mt-4">
          <h1 className="text-[22px] tracking-[-1px] leading-[145%] text-[#111111] font-medium text-center">
            {/* Refer a friend */}
            {getLocale("referFriend")}
          </h1>
          <p className="text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal text-center ">
            {/* Refer a friend in KMBIO and recieve benefits */}
            {getLocale("referFriendInfo")}
          </p>
        </div>
        <div className="mt-[50px] w-full rounded-md border border-[#CFCFCF] py-2 flex items-center justify-between px-2">
          <p className="text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal text-center">
            {`${window.location.origin}/register/?refer=${user?.refral}`}
          </p>
          <CopyToClipboard
            text={`${window.location.origin}/register/?refer=${user?.refral}`}
          >
            <button className="rounded-md border border-[#CFCFCF] py-2 px-4 text-center text-[16px] tracking-normal leading-[140%] font-medium">
              {getLocale("copy")}
            </button>
          </CopyToClipboard>
        </div>
        <button className="w-full p-4 bg-bgBlue text-white rounded-lg mt-2 text-base">
          {/* Share */}
          {getLocale("share")}
        </button>
      </div>
    </div>
  );
}

export default Refer;
