// import { Button, Drawer } from "@mui/material";
import {
  IconAlarm,
  IconArrowNarrowDown,
  IconArrowsExchange,
  IconCaretDownFilled,
  IconCaretUpFilled,
  IconChartBar,
  IconColumns3,
  IconDiamond,
  IconHome,
  IconX,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getLocale } from "../../constants/language";
import {
  cancleOrder,
  getAllTransaction,
  getGraph,
  getOrderBook,
  getTradeHistory,
  getTradingPairOrder,
  getWallet,
  placePairOrder,
  walletTransfer,
} from "../../service/api";
import { Danger } from "../Core/Errors";
import { ROUTES } from "../../constants/routes";
import w1 from "../../Assets/image/Wallet-1.svg";
import w2 from "../../Assets/image/Wallet-2.svg";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Skeleton,
} from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { NumericFormat } from "react-number-format";
import Loader from "../Core/Loader";
import { formatValue } from "../../service/utils";

const BottomDrawer = () => {
  const [open, setOpen] = useState(false);
  const [wopen, setWopen] = useState(false);
  const navigate = useNavigate();
  const { activePair, user } = useSelector((state) => state.user);
  const [activeTabTransfer, setActiveTabTransfer] = useState({
    from: "Trading",
    to: "Funding",
  });
  const [isBuyActive, setIsBuyActive] = useState(true);
  const [checked, setChecked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isPair, setIsPair] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [loaderAdd, setLoaderAdd] = useState(false);
  const [limit, setLimit] = useState();
  const [balance, setBalance] = useState();
  const [bal, setBal] = useState({ spot: [], funding: [] });
  const [data, setData] = useState([]);
  const [amount, setAmount] = useState("");
  const [amountTr, setAmountTr] = useState();
  const [err, setErr] = useState({ limitErr: false, amountErr: false });
  const [errTr, setErrTr] = useState({ amountTr: false, coin: false });
  const [selectedOrderType, setSelectedOrderType] = useState("LIMIT");
  const [currTab, setCurrTab] = useState("CHART");
  const [selectedCoin, setSelectedCoin] = useState(activePair?.toCoin?.symbol);
  const [selectedCoinTr, setSelectedCoinTr] = useState();
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [tradeData, setTradeData] = useState([]);
  const [timeFrame, setTimeFrame] = useState("1D");
  const [order, setOrder] = useState("OPEN");
  const [slider, setSlider] = useState({ buy: 0, sell: 0 });

  const getData = () => {
    let params = {
      page: 1,
      sizePerPage: 100,
      pagination: false,
      orderStatus: order === "CAN" ? "CANCELLED" : "OPEN",
    };
    if (checked) {
      params.pairId = activePair?._id;
    }

    setLoading(true);
    getTradingPairOrder(params)
      ?.then((res) => {
        if (res?.status) {
          setData(res?.data?.result);
        }
      })

      .finally(() => setLoading(false));
  };

  const getTradeHistoryData = () => {
    let params = { page: 1, sizePerPage: 100, pagination: false };
    if (checked) {
      params.pairId = activePair?._id;
    }

    setLoading(true);
    getTradeHistory(params)
      ?.then((res) => {
        if (res?.status) {
          setData(res?.data?.result);
        }
      })

      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (order === "CAN" || order === "OPEN") {
      setData([]);
      getData();
    } else {
      setData([]);
      getTradeHistoryData();
    }
  }, [order, checked]);

  const handleCancel = (id) => {
    if (cancelLoading) {
      return;
    }
    let body = {
      orderStatus: "CANCELLED",
    };
    setCancelLoading(true);
    cancleOrder(id, body)
      ?.then((res) => {
        if (res?.status) {
          getData();
        }
      })
      .catch((e) => {})
      .finally(() => setCancelLoading(false));
  };

  const getGraphData = () => {
    const currentDate = moment();
    let startDate;

    if (timeFrame === "1H") {
      startDate = moment().subtract(1, "hour");
    } else if (timeFrame === "1D") {
      startDate = moment().subtract(1, "day");
    } else if (timeFrame === "1W") {
      startDate = moment().subtract(1, "week");
    } else if (timeFrame === "1M") {
      startDate = moment().subtract(1, "month");
    } else if (timeFrame === "1Y") {
      startDate = moment().subtract(1, "year");
    } else {
      startDate = currentDate;
    }
    let params = {
      pairId: activePair?._id,
      //   pairId: "670ea181d96301f59e3ffb7e",
    };

    if (timeFrame !== "All") {
      params.startDate = startDate;
      params.endDate = currentDate;
    }
    setLoading(true);
    getGraph(params)
      .then((res) => {
        if (res?.status) {
          const prices =
            res?.data && res?.data?.length > 0
              ? res?.data?.map((item) => item.price)
              : [
                  +activePair?.currentPrice - +activePair?.currentPrice * 0.8,
                  +activePair?.currentPrice + +activePair?.currentPrice * 0.6,
                  +activePair?.currentPrice,
                ];
          const labels =
            res?.data && res?.data?.length > 0
              ? res?.data?.map((item) =>
                  moment(item.createdAt).format("DD-MM-YYYY hh:mm A")
                )
              : [
                  moment().subtract(10, "minute"),
                  moment().subtract(1, "minute"),
                  moment(new Date()).format("DD-MM-YYYY hh:mm A"),
                ];
          setChartData(prices);
          setChartLabels(labels);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (currTab === "CHART" && activePair) {
      getGraphData();
    }
  }, [activePair, timeFrame, currTab]);

  const options = {
    chart: {
      type: "line",
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      title: {
        text: "Month",
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      x: {
        formatter: function (value) {
          return chartLabels[value];
        },
      },
      y: {
        formatter: function (value) {
          return `Price: ${value?.toFixed(2)}`;
        },
      },
      y: {
        title: {
          formatter: () => "",
        },
      },
    },
  };

  const series = [
    {
      data: chartData,
    },
  ];

  const orderBookData = () => {
    let params = {
      pairId: activePair?._id,
      value: 0.1,
    };
    getOrderBook(params)?.then((res) => {
      if (res?.status) {
        setOrderData(res?.data);
        let buy =
          res?.data?.length > 0
            ? res?.data[0]?.buySide?.reduce(
                (acc, crr) => acc + +crr?.totalQuantity,
                0
              )
            : 0;
        let sell =
          res?.data?.length > 0
            ? res?.data[0]?.sellSide?.reduce(
                (acc, crr) => acc + +crr?.totalQuantity,
                0
              )
            : 0;

        let total = buy + sell;

        if (total > 0) {
          setSlider({
            buy: (buy / total) * 100,
            sell: (sell / total) * 100,
          });
        } else {
          setSlider({ buy: 0, sell: 0 });
        }
      }
    });
  };

  const getTradeData = () => {
    let params = {
      pairId: activePair?._id,
    };
    getAllTransaction(params)?.then((res) => {
      if (res?.status) {
        setTradeData(res?.data?.result);
      }
    });
  };

  useEffect(() => {
    if (currTab === "TRADE") {
      getTradeData();
    } else if (currTab === "ORDER") {
      orderBookData();
    }
  }, [currTab]);

  const getWalletBalance = () => {
    try {
      Promise.all([
        getWallet({ walletType: "SPOT" }),
        getWallet({ walletType: "FUNDING" }),
      ]).then(([spotRes, fundingRes]) => {
        const spotData = spotRes?.data[0]?.data;
        const fundingData = fundingRes?.data[0]?.data;

        setBal({ spot: spotData, funding: fundingData });
        let balanceData = spotRes?.data[0]?.data;
        if (isBuyActive) {
          let blc =
            balanceData &&
            balanceData?.length > 0 &&
            balanceData?.find(
              (obj) => activePair?.toCoin?._id === obj?.coin?._id
            );

          setBalance(blc ? blc?.amount : 0);
        } else {
          let blc =
            balanceData &&
            balanceData?.length > 0 &&
            balanceData?.find(
              (obj) => activePair?.fromCoin?._id === obj?.coin?._id
            );
          setBalance(blc ? blc?.amount : 0);
        }
      });
    } catch (error) {}
  };

  const handleTransfer = () => {
    if (!amountTr || amountTr <= 0) {
      Danger("Enter Valid Amount");
      setErrTr({ ...errTr, amountTr: true });
      return;
    } else if (!selectedCoinTr) {
      setErrTr({ ...errTr, coin: true });
      Danger("Please Select Coin");
      return;
    } else if (selectedCoinTr?.amount < amountTr) {
      Danger("Please Enter amount you had in your wallet");
      return;
    }
    let body = {
      coin: selectedCoinTr?.coin?._id,
      amount: Number(amountTr),
      fromWalletType:
        activeTabTransfer?.from === "Trading" ? "SPOT" : "FUNDING",
      toWalletType: activeTabTransfer?.to === "Trading" ? "SPOT" : "FUNDING",
    };
    setLoaderAdd(true);
    walletTransfer(body)
      ?.then((res) => {
        if (res?.status) {
          setAmountTr(0);
          getWalletBalance();
          setWopen(false);
          setSelectedCoinTr();
        }
      })
      .catch((e) => {
        Danger(e?.response?.data?.message);
      })
      .finally(() => setLoaderAdd(false));
  };

  const handleOrder = () => {
    if (selectedOrderType === "LIMIT" && limit <= 0) {
      setErr({ ...err, limitErr: true });
      Danger("Limit price must be greater than zero");
      return;
    } else if (
      selectedOrderType === "LIMIT" &&
      isBuyActive &&
      limit > activePair?.currentPrice
    ) {
      setErr({ ...err, limitErr: true });
      Danger("Limit price must be less than current price");
      return;
    } else if (
      selectedOrderType === "LIMIT" &&
      !isBuyActive &&
      limit < activePair?.currentPrice
    ) {
      setErr({ ...err, limitErr: true });
      Danger("Limit price must be greater than current price");
      return;
    } else if (!amount || amount <= 0) {
      setErr({ ...err, amountErr: true });
      Danger(getLocale("greaterThen"));
      return;
    } else if (selectedOrderType === "LIMIT" && isBuyActive) {
      if (selectedCoin === activePair?.toCoin?.symbol && balance < amount) {
        setErr({ ...err, amountErr: true });
        Danger(getLocale("noBalance"));
        return;
      } else if (
        selectedCoin === activePair?.fromCoin?.symbol &&
        balance / +activePair?.currentPrice < amount
      ) {
        setErr({ ...err, amountErr: true });
        Danger(getLocale("noBalance"));
        return;
      }
    } else if (selectedOrderType === "LIMIT" && !isBuyActive) {
      if (selectedCoin === activePair?.fromCoin?.symbol && balance < amount) {
        setErr({ ...err, amountErr: true });
        Danger(getLocale("noBalance"));
        return;
      } else if (
        selectedCoin === activePair?.toCoin?.symbol &&
        balance * +activePair?.currentPrice < amount
      ) {
        setErr({ ...err, amountErr: true });
        Danger(getLocale("noBalance"));
        return;
      }
    }
    let body = {
      pairId: activePair?._id,
      type: selectedOrderType,
      orderType: isBuyActive ? "BUY" : "SELL",
      // price:
      //   selectedOrderType === "LIMIT"
      //     ? Number(limit)
      //     : Number(activePair?.currentPrice),
      // amount:
      //   selectedCoin === activePair?.fromCoin?.symbol ? amount : limit * amount,
      // quantity:
      //   selectedCoin === activePair?.toCoin?.symbol ? amount : amount / limit,
    };
    if (selectedOrderType === "LIMIT") {
      body.amount =
        selectedCoin === activePair?.toCoin?.symbol
          ? Number(amount)
          : Number(limit * amount);
      body.quantity =
        selectedCoin === activePair?.fromCoin?.symbol
          ? Number(amount)
          : Number(amount / limit);
      body.price = Number(limit);
    } else {
      if (selectedCoin === activePair?.toCoin?.symbol) {
        body.amount = Number(amount);
      } else {
        body.quantity = Number(amount);
      }
    }

    setAddLoading(true);
    placePairOrder(body)
      ?.then((res) => {
        if (res?.status) {
          setLimit(activePair?.currentPrice);
          // setAmount("");
          // navigate(ROUTES.PAIRCOIN);
          getWalletBalance();
          setOpen(false);
        } else {
          Danger(res?.message);
        }
      })
      .catch((e) => {
        Danger(e?.response?.data?.message);
      })
      .finally(() => setAddLoading(false));
  };

  useEffect(() => {
    getWalletBalance();
  }, [isBuyActive]);

  return (
    <div className="flex flex-col items-center justify-start min-h-screen bg-white pb-[95px]">
      {/* <Button
        color="blue"
        onClick={() => {
          setOpen(!open);
          setLimit();
          setAmount("");
          setSelectedOrderType("LIMIT")
        }}
      >
        Open Bottom Drawer
      </Button> */}

      <div className="flex items-center justify-between w-full border p-2 bg-slate-100 mt-2">
        <div class="flex flex-col justify-between items-center">
          <div>
            <h2 class="text-lg font-bold">
              {activePair?.fromCoin?.symbol} / {activePair?.toCoin?.symbol}
            </h2>
          </div>

          <div class="mt-2">
            <h1 class="text-xl font-bold text-red-500">
              {activePair?.currentPrice}
            </h1>
            <p class="text-sm text-red-400">
              -1.04% <span class="text-red-500">-1,100.9</span>
            </p>
          </div>
        </div>

        <div class="grid grid-cols-2 gap-3 mt-2 text-xs text-gray-500 text-left">
          <div>
            <p class="font-medium">24h High</p>
            <p>{formatValue(+activePair?.maxTradeAmount)}</p>
          </div>
          <div>
            <p class="font-medium">24h Low</p>
            <p>{formatValue(+activePair?.minTradeAmount)}</p>
          </div>
          <div>
            <p class="font-medium">
              24h Volume ({activePair?.fromCoin?.symbol})
            </p>
            <p>{formatValue(+activePair?.fromCoinVolume)}</p>
          </div>
          <div>
            <p class="font-medium">24h Volume ({activePair?.toCoin?.symbol})</p>
            <p>{formatValue(+activePair?.toCoinVolume)}</p>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="flex w-[95%] mx-auto mt-2 gap-2 rounded-full overflow-hidden">
          <button
            onClick={() => {
              setIsBuyActive(true);
              setOpen(true);
            }}
            className={`py-1 bg-green-500 text-white font-semibold flex-1 rounded-full`}
          >
            {/* Buy */}
            {getLocale("buy")}
          </button>
          <button
            onClick={() => {
              setIsBuyActive(false);
              setOpen(true);
            }}
            className={`py-1 bg-red-500 text-white font-semibold flex-1 rounded-full`}
          >
            {getLocale("sell")}
          </button>
        </div>
      </div>
      <div class="bg-slate-100 mt-2 text-black p-2 border w-[100%] mx-auto">
        <div className="flex justify-start gap-3 items-center w-full mx-auto border-b pb-0 mb-1">
          <p
            onClick={() => {
              setCurrTab("CHART");
            }}
            className={`border-b-2 pb-1 ${
              currTab === "CHART"
                ? "font-bold text-gray-800 border-black"
                : "font-medium text-gray-600 border-transparent"
            } text-sm cursor-pointer`}
          >
            Chart
          </p>
          <p
            onClick={() => {
              setCurrTab("ORDER");
            }}
            className={`border-b-2 pb-1 ${
              currTab === "ORDER"
                ? "font-bold text-gray-800 border-black"
                : "font-medium text-gray-600 border-transparent"
            } text-sm cursor-pointer`}
          >
            Order Book
          </p>
          <p
            onClick={() => {
              setCurrTab("TRADE");
            }}
            className={`border-b-2 pb-1 ${
              currTab === "TRADE"
                ? "font-bold text-gray-800 border-black"
                : "font-medium text-gray-600 border-transparent"
            } text-sm cursor-pointer`}
          >
            Trades
          </p>
          <p
            onClick={() => {
              setCurrTab("COIN");
            }}
            className={`border-b-2 pb-1 ${
              currTab === "COIN"
                ? "font-bold text-gray-800 border-black"
                : "font-medium text-gray-600 border-transparent"
            } text-sm cursor-pointer`}
          >
            Coin Info
          </p>
        </div>
        <div className="h-[300px] overflow-y-auto">
          {currTab === "CHART" && (
            <>
              {loading ? (
                <div className="bg-transparent border border-gray-600 rounded-lg p-4 w-full h-[229px] mx-auto flex items-center justify-center">
                  <CircularProgress />
                </div>
              ) : chartData && chartData?.length > 0 ? (
                <ReactApexChart
                  options={options}
                  series={series}
                  type="line"
                  // height={350}
                />
              ) : (
                <div className="bg-transparent border border-gray-600 rounded-lg p-4 w-full h-[229px] mx-auto text-black font-medium text-lg">
                  {/* No graph data found */}
                  {getLocale("noGraph")}
                </div>
              )}
              <div className="flex justify-around items-center mt-2">
                <div
                  className={`text-base ${
                    timeFrame === "1H" ? "text-[#26a0fc]" : "text-[#1f1f1fab]"
                  } font-medium mt-2 cursor-pointer`}
                  onClick={() => setTimeFrame("1H")}
                >
                  1H
                </div>
                <div
                  className={`text-base ${
                    timeFrame === "1D" ? "text-[#26a0fc]" : "text-[#1f1f1fab]"
                  } font-medium mt-2 cursor-pointer`}
                  onClick={() => setTimeFrame("1D")}
                >
                  1D
                </div>
                <div
                  className={`text-base ${
                    timeFrame === "1W" ? "text-[#26a0fc]" : "text-[#1f1f1fab]"
                  } font-medium mt-2 cursor-pointer`}
                  onClick={() => setTimeFrame("1W")}
                >
                  1W
                </div>
                <div
                  className={`text-base ${
                    timeFrame === "1M" ? "text-[#26a0fc]" : "text-[#1f1f1fab]"
                  } font-medium mt-2 cursor-pointer`}
                  onClick={() => setTimeFrame("1M")}
                >
                  1M
                </div>
                <div
                  className={`text-base ${
                    timeFrame === "1Y" ? "text-[#26a0fc]" : "text-[#1f1f1fab]"
                  } font-medium mt-2 cursor-pointer`}
                  onClick={() => setTimeFrame("1Y")}
                >
                  1Y
                </div>
                <div
                  className={`text-base ${
                    timeFrame === "All" ? "text-[#26a0fc]" : "text-[#1f1f1fab]"
                  } font-medium mt-2 cursor-pointer`}
                  onClick={() => setTimeFrame("All")}
                >
                  {/* All */}
                  {getLocale("all")}
                </div>
              </div>
            </>
          )}

          {currTab === "ORDER" && (
            <div className="mt-3">
              <div className="flex items-center justify-center w-full gap-2">
                <p className="text-sm mb-0 text-green-500">
                  {formatValue(slider?.buy)}%
                </p>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={slider?.buy}
                  className="slider-thumb h-1.5 w-full rounded-xl appearance-none bg-gray-300"
                  style={{
                    background: `linear-gradient(to right, green ${slider?.buy}%, red ${slider?.sell}%)`,
                  }}
                />
                <p className="text-sm mb-0 text-red-500">
                  {formatValue(slider?.sell)}%
                </p>
              </div>
              <div className="flex items-start justify-center w-full gap-2">
                <div className="flex w-1/2 flex-col items-center justify-start">
                  <div className="flex items-start justify-between w-full mb-1">
                    <p className="text-sm mb-0 text-black">Bid</p>
                    <p></p>
                  </div>
                  {orderData?.length > 0 &&
                    orderData[0] &&
                    orderData[0]?.buySide?.length > 0 &&
                    orderData[0]?.buySide?.map((obj, index) => {
                      return (
                        <div
                          key={index}
                          className="flex items-start justify-between w-full"
                        >
                          <p className="text-xs mb-0 text-black">
                            {formatValue(obj?.totalQuantity)}
                          </p>
                          <p className="text-xs mb-0 text-green-500">
                            {obj?._id}
                          </p>
                        </div>
                      );
                    })}
                </div>
                <div className="flex w-1/2 flex-col items-center justify-start">
                  <div className="flex items-start justify-between w-full mb-1">
                    <p className="text-sm mb-0 text-black">Ask</p>
                    <p></p>
                  </div>
                  {orderData?.length > 0 &&
                    orderData[0] &&
                    orderData[0]?.sellSide?.length > 0 &&
                    orderData[0]?.sellSide?.map((obj, index) => {
                      return (
                        <div
                          key={index}
                          className="flex items-start justify-between w-full"
                        >
                          <p className="text-xs mb-0 text-red-500">
                            {obj?._id}
                          </p>
                          <p className="text-xs mb-0 text-black">
                            {formatValue(obj?.totalQuantity)}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}

          {currTab === "TRADE" && (
            <div className="mt-3 w-full ">
              <div className="flex justify-between items-center w-full mb-2">
                <p className="text-xs mb-0 text-black">Time</p>
                <p className="text-xs mb-0 text-black">Price</p>
                <p className="text-xs mb-0 text-black">Amount</p>
              </div>
              {tradeData && tradeData?.length > 0 ? (
                tradeData?.map((obj, index) => {
                  return (
                    <div
                      key={index}
                      className="flex justify-between items-center w-full"
                    >
                      <p className="text-xs mb-0 text-black">
                        {moment(obj?.createdAt).format("HH:mm:ss")}
                      </p>
                      <p
                        className={`text-xs mb-0 text-left 
                                  ${
                                    !obj?.buyOrderId
                                      ? "text-green-500"
                                      : "text-red-500"
                                  }
                                `}
                      >
                        {obj?.price}
                      </p>
                      <p className="text-xs mb-0 text-black">
                        {formatValue(obj?.quantity)}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div className="text-xs mt-2 text-black">No trades found</div>
              )}
            </div>
          )}

          {currTab === "COIN" && (
            <div className="mt-3">
              <div>
                <div className=" flex gap-3 mt-2 justify-between items-center">
                  <div className=" flex gap-3  items-center">
                    <IconHome color="#6DA8FF" size={20} />
                    <Link className="text-sm font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left opacity-50">
                      {/* Market Cap */}
                      {getLocale("marketCap")}
                    </Link>
                  </div>
                  <p className="text-sm font-semibold text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                    $723.6 billion
                  </p>
                </div>

                <div className=" flex gap-3 mt-5 justify-between items-center">
                  <div className=" flex gap-3  items-center">
                    <IconColumns3 color="#6DA8FF" size={20} />
                    <Link className="text-sm opacity-50 font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                      {/* Volumen  */}
                      {getLocale("volumen")} {activePair?.fromCoin?.name}
                    </Link>
                  </div>
                  <p className="text-sm font-semibold text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                    {formatValue(activePair?.fromCoinVolume)}
                  </p>
                </div>
                <div className=" flex gap-3 mt-5 justify-between items-center">
                  <div className=" flex gap-3  items-center">
                    <IconColumns3 color="#6DA8FF" size={20} />
                    <Link className="text-sm opacity-50 font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                      {/* Volumen  */}
                      {getLocale("volumen")} {activePair?.toCoin?.name}
                    </Link>
                  </div>
                  <p className="text-sm font-semibold text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                    {formatValue(activePair?.toCoinVolume)}
                  </p>
                </div>
                <div className=" flex gap-3 mt-5 justify-between items-center">
                  <div className=" flex gap-3  items-center">
                    <IconColumns3 color="#6DA8FF" size={20} />
                    <Link className="text-sm opacity-50 font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                      {/* Oferta circulante */}
                      {getLocale("oferta")}
                    </Link>
                  </div>
                  <p className="text-sm font-semibold text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                    $35.6 billion
                  </p>
                </div>
                <div className=" flex gap-3 mt-5 justify-between items-center">
                  <div className=" flex gap-3  items-center">
                    <IconChartBar color="#6DA8FF" size={20} />
                    <Link className="text-sm opacity-50 font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                      {/* Actividad comercial */}
                      {getLocale("actComercial")}
                    </Link>
                  </div>
                  <p className="text-sm font-semibold text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                    $36.6% {getLocale("sell")}
                  </p>
                </div>
                <div className=" flex gap-3 mt-5 justify-between items-center">
                  <div className=" flex gap-3  items-center">
                    <IconAlarm color="#6DA8FF" size={20} />
                    <Link className="text-sm opacity-50 font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                      {/* Tiempo típico de retención. */}
                      {getLocale("retentionTime")}
                    </Link>
                  </div>
                  <p className="text-sm font-semibold text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                    53 {getLocale("days")}
                  </p>
                </div>
                <div className=" flex gap-3 mt-5 justify-between items-center">
                  <div className=" flex gap-3  items-center">
                    <IconDiamond color="#6DA8FF" size={20} />
                    <Link className="text-sm opacity-50 font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                      {/* Popularidad en KMBIO. */}
                      {getLocale("popularity")}
                    </Link>
                  </div>
                  <p className="text-sm font-semibold text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                    #3
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div class="bg-slate-100 mt-2 text-black p-2 border w-[100%] mx-auto">
        <div class="mb-4">
          <div class="flex justify-start items-left mb-2">
            <h3 class="text-sm font-medium">Trading Account</h3>
          </div>
          <div class="flex justify-between text-sm text-gray-500">
            <p>{activePair?.fromCoin?.symbol}</p>
            <p className="text-black font-bold">
              {bal?.spot && bal?.spot?.length > 0
                ? formatValue(
                    bal?.spot?.find(
                      (obj) =>
                        obj?.coin?.symbol === activePair?.fromCoin?.symbol
                    )?.amount
                  ) || 0
                : 0}
            </p>
          </div>
          <div class="flex justify-between text-sm text-gray-500">
            <p>{activePair?.toCoin?.symbol}</p>
            <p className="text-black font-bold">
              {bal?.spot && bal?.spot?.length > 0
                ? formatValue(
                    bal?.spot?.find(
                      (obj) => obj?.coin?.symbol === activePair?.toCoin?.symbol
                    )?.amount
                  ) || 0
                : 0}
            </p>{" "}
          </div>
        </div>

        <div class="mb-4">
          <h3 class="text-sm text-left font-medium mb-2">Funding Account</h3>
          <div class="flex justify-between text-sm text-gray-500">
            <p>{activePair?.fromCoin?.symbol}</p>
            <p className="text-black font-bold">
              {bal?.funding && bal?.funding?.length > 0
                ? formatValue(
                    bal?.funding?.find(
                      (obj) =>
                        obj?.coin?.symbol === activePair?.fromCoin?.symbol
                    )?.amount
                  ) || 0
                : 0}
            </p>{" "}
          </div>
          <div class="flex justify-between text-sm text-gray-500">
            <p>{activePair?.toCoin?.symbol}</p>
            <p className="text-black font-bold">
              {bal?.funding && bal?.funding?.length > 0
                ? formatValue(
                    bal?.funding?.find(
                      (obj) => obj?.coin?.symbol === activePair?.toCoin?.symbol
                    )?.amount
                  ) || 0
                : 0}
            </p>{" "}
          </div>
        </div>

        <div class="flex justify-between gap-2">
          <button
            class="bg-gray-700 w-1/2 text-white px-4 py-1 rounded-full text-sm font-bold hover:bg-gray-600"
            onClick={() => {
              navigate(ROUTES.SELECT_ASSETS_TO_BUY);
            }}
          >
            Deposit
          </button>
          <button
            onClick={() => setWopen(!wopen)}
            class="bg-gray-700 w-1/2 text-white px-4 py-1 rounded-full text-sm font-bold hover:bg-gray-600"
          >
            Transfer
          </button>
        </div>
      </div>
      <div class="bg-slate-100 mt-2 text-black p-2 border w-[100%] mx-auto">
        <div className="flex justify-start gap-3 items-center w-full mx-auto border-b pb-0 mb-1">
          <p
            onClick={() => {
              setOrder("OPEN");
            }}
            className={`border-b-2 pb-1 ${
              order === "OPEN"
                ? "font-bold text-gray-800 border-black"
                : "font-medium text-gray-600 border-transparent"
            } text-sm cursor-pointer`}
          >
            Open Orders
          </p>
          <p
            onClick={() => {
              setOrder("COMP");
            }}
            className={`border-b-2 pb-1 ${
              order === "COMP"
                ? "font-bold text-gray-800 border-black"
                : "font-medium text-gray-600 border-transparent"
            } text-sm cursor-pointer`}
          >
            Completed Order
          </p>
          <p
            onClick={() => {
              setOrder("CAN");
            }}
            className={`border-b-2 pb-1 ${
              order === "CAN"
                ? "font-bold text-gray-800 border-black"
                : "font-medium text-gray-600 border-transparent"
            } text-sm cursor-pointer`}
          >
            Cancelled Orders
          </p>
        </div>
        <div className="text-left flex items-center justify-start gap-1">
          <Checkbox
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<CheckBox fontSize="small" color="primary" />}
          />

          <label className="text-left font-semibold text-sm">
            {" "}
            {getLocale("currentPair")}
          </label>
        </div>
        <div className="mt-3">
          {loading ? (
            Array.from({ length: 4 }).map((_, index) => (
              <Skeleton key={index} sx={{ height: "25px" }} />
            ))
          ) : data && data?.length > 0 ? (
            data?.map((obj, index) => (
              <div
                key={index}
                className="flex justify-between items-center border border-[#d1d1d1] rounded-lg cursor-pointer hover:bg-[#f9f9f9] shadow-sm p-4 mb-3 transition-all duration-200"
                onClick={() => navigate(`/PairOrderStatus/${obj?._id}?Open`)}
              >
                <div className="flex items-center gap-3">
                  <div
                    className={`p-2 rounded-full ${
                      obj?.orderType === "BUY" ? "bg-green-200" : "bg-red-200"
                    }`}
                  >
                    {obj?.orderType === "BUY" ? (
                      <i className="text-green-600 fas fa-arrow-up"></i>
                    ) : (
                      <i className="text-red-600 fas fa-arrow-down"></i>
                    )}
                  </div>

                  <div className="text-left">
                    <h1 className="text-[16px] font-medium leading-[140%] tracking-[0.2px] text-[#111111]">
                      {obj?.orderType} {obj?.fromCoin?.symbol}/
                      {obj?.toCoin?.symbol}
                    </h1>
                    <p className="text-[12px] leading-[145%] tracking-normal text-[#707070] mt-1">
                      {moment(obj?.createdAt)?.format("DD/MM/YYYY hh:mm A")}
                    </p>
                    <div
                      className={`text-[12px] w-fit px-2 text-[#fff] mt-1 ${
                        obj?.orderStatus === "CANCELLED"
                          ? "bg-red-500"
                          : "bg-gray-500"
                      } rounded-2xl`}
                    >
                      {obj?.orderStatus}
                    </div>
                  </div>
                </div>

                <div className="text-right">
                  {obj?.type === "LIMIT" ? (
                    <>
                      {" "}
                      <h1 className="text-[16px] font-bold leading-[140%] tracking-[0.2px] text-[#111111]">
                        <NumericFormat
                          value={obj?.pendingQuantity}
                          displayType="text"
                          decimalScale={4}
                          thousandSeparator
                        />{" "}
                        {obj?.fromCoin?.symbol}
                      </h1>
                      <p className="text-[14px] leading-[145%] tracking-normal text-[#707070]">
                        {formatValue(parseFloat(obj?.pendingAmount))}{" "}
                        {obj?.toCoin?.symbol}
                      </p>
                    </>
                  ) : (
                    <h1 className="text-[16px] font-bold leading-[140%] tracking-[0.2px] text-[#111111]">
                      {obj?.orderType === "BUY"
                        ? obj?.quantity
                          ? `${formatValue(obj?.quantity)} ${
                              obj?.fromCoin?.symbol
                            }`
                          : `${formatValue(obj?.amount)} ${obj?.toCoin?.symbol}`
                        : obj?.quantity
                        ? `${formatValue(obj?.quantity)} ${obj?.toCoin?.symbol}`
                        : `${formatValue(obj?.amount)} ${
                            obj?.fromCoin?.symbol
                          }`}
                    </h1>
                  )}
                  {obj?.type === "LIMIT" &&
                    obj?.orderStatus !== "CANCELLED" && (
                      <div
                        onClick={() => handleCancel(obj?._id)}
                        className="text-[12px] text-right float-right w-fit px-2 hover:text-[#fff] mt-1 hover:bg-gray-500 rounded-2xl"
                      >
                        {/* Cancel Order */}
                        {getLocale("candelOrder")}
                      </div>
                    )}
                </div>
              </div>
            ))
          ) : (
            <div className="bg-transparent border border-gray-600 rounded-lg p-4 w-[90%] mx-auto text-black font-medium text-lg mt-6">
              {/* No any order found */}
              {getLocale("noOrder")}
            </div>
          )}
        </div>
      </div>
      <PopUp open={open} setisLite={setOpen}>
        <>
          {addLoading && <Loader />}
          <div className="flex flex-col h-[75vh] max-h-[75vh] items-center border bg-white rounded-t-2xl">
            <div className="flex items-center justify-between w-full border-b p-2">
              <h2 className="text-lg font-bold">Place Order</h2>
              <IconX
                onClick={() => setOpen(!open)}
                className="rounded-full cursor-pointer border-2 p-1 size-6"
              />
            </div>
            <div className="h-full mx-auto overflow-y-auto w-full">
              <div className="flex w-[95%] mx-auto mt-4 border border-gray-300 rounded-full overflow-hidden">
                <button
                  onClick={() => {
                    setIsBuyActive(true);
                    setSelectedCoin(activePair?.toCoin?.symbol);
                  }}
                  className={`py-1 ${
                    isBuyActive ? "bg-green-500 text-white" : "text-gray-800"
                  } font-semibold flex-1 rounded-full`}
                >
                  {/* Buy */}
                  {getLocale("buy")}
                </button>
                <button
                  onClick={() => {
                    setIsBuyActive(false);
                    setSelectedCoin(activePair?.fromCoin?.symbol);
                  }}
                  className={`py-1 ${
                    !isBuyActive ? "bg-red-500 text-white" : "text-gray-800"
                  } font-semibold flex-1 rounded-full`}
                >
                  {/* Sell */}
                  {getLocale("sell")}
                </button>
              </div>
              <div className="flex justify-start gap-4 items-center w-[95%] mx-auto border-b pt-3 pb-0 mb-1">
                <p
                  onClick={() => {
                    setSelectedOrderType("LIMIT");
                    setLimit(activePair?.currentPrice);
                    setErr({ ...err, limitErr: false });
                  }}
                  className={`border-b-2 mb-0 ${
                    selectedOrderType === "LIMIT"
                      ? "font-bold text-gray-800 border-black"
                      : "font-medium text-gray-600 border-transparent"
                  } text-sm cursor-pointer`}
                >
                  Limit
                </p>
                <p
                  onClick={() => {
                    setSelectedOrderType("MARKET");
                    setLimit(activePair?.currentPrice);
                  }}
                  className={`border-b-2 ${
                    selectedOrderType === "MARKET"
                      ? "font-bold text-gray-800 border-black"
                      : "font-medium text-gray-600 border-transparent"
                  } text-sm cursor-pointer`}
                >
                  Market
                </p>
              </div>

              <div className="flex justify-between items-center my-2 w-[95%] mx-auto">
                <label className="text-gray-600 text-sm font-medium opacity-70">
                  {/* Available */}
                  {getLocale("available")}
                </label>
                <label className="text-gray-800 text-sm font-medium opacity-100">
                  {formatValue(balance)}{" "}
                  {isBuyActive
                    ? activePair?.toCoin?.symbol
                    : activePair?.fromCoin?.symbol}
                </label>
              </div>
              <div className="flex justify-between items-center w-[95%] mx-auto my-2">
                <label className="text-gray-600 text-xs font-medium opacity-70">
                  {/* Max {isBuyActive ? "Buy" : "Sell"} */}
                  {/* Max Buy */}
                  {getLocale("max")}
                  {getLocale("buy")}
                </label>
                <label className="text-gray-800 text-xs font-medium opacity-100">
                  {isBuyActive
                    ? formatValue(balance / +activePair?.currentPrice)
                    : formatValue(balance * +activePair?.currentPrice)}{" "}
                  {!isBuyActive
                    ? activePair?.toCoin?.symbol
                    : activePair?.fromCoin?.symbol}
                </label>
              </div>
              <div className="inline-block w-[95%] mt-1 mx-auto">
                <div
                  className={`w-full rounded-md relative ${
                    selectedOrderType === "MARKET" ? "bg-gray-300" : "bg-white"
                  } text-black py-1 border ${
                    err?.limitErr ? "border-red-500" : "border-gray-300"
                  } overflow-hidden flex justify-between`}
                >
                  <input
                    type="number"
                    placeholder="Price"
                    value={
                      selectedOrderType === "MARKET"
                        ? +activePair?.currentPrice
                        : limit
                    }
                    disabled={selectedOrderType === "MARKET"}
                    onChange={(e) => {
                      if (e.target.value) {
                        setLimit(Number(e.target.value));
                      } else {
                        setLimit();
                      }
                      setErr({ ...err, limitErr: false });
                    }}
                    className="w-full px-2 text-base text-left focus:outline-none bg-transparent"
                  />
                  <div className="flex flex-col space-y-[2px] mr-1">
                    <button className="w-6 h-4 bg-gray-500 hover:bg-gray-600 flex items-center justify-center rounded-t-md">
                      <IconCaretUpFilled
                        onClick={() => {
                          setLimit(
                            limit >= activePair?.currentPrice
                              ? activePair?.currentPrice
                              : limit + 1
                          );
                          setErr({ ...err, limitErr: false });
                        }}
                        className="size-3 text-white"
                      />
                    </button>
                    <button className="w-6 h-4 bg-gray-500 hover:bg-gray-600 flex items-center justify-center rounded-b-md">
                      <IconCaretDownFilled
                        onClick={() => {
                          setLimit(limit === 0 ? 0 : limit - 1);
                          setErr({ ...err, limitErr: false });
                        }}
                        className="size-3 text-white"
                      />
                    </button>
                  </div>
                </div>
              </div>

              <div
                className={`w-[95%] mx-auto rounded-md flex justify-center mt-2 items-center gap-2 bg-white text-black border ${
                  err?.amountErr ? "border-red-500" : "border-gray-300"
                }`}
              >
                <input
                  type="number"
                  placeholder={"Amount"}
                  value={amount}
                  onChange={(e) => {
                    if (e.target.value) {
                      setAmount(Number(e.target.value));
                    } else {
                      setAmount();
                    }
                    setErr({ ...err, amountErr: false });
                  }}
                  className="w-[70%] px-2 text-base text-left focus:outline-none"
                />

                <div className="relative pl-3 my-2 border-l border-gray-700 inline-block w-[30%] mx-auto ">
                  <button
                    onClick={() => setIsPair(!isPair)}
                    className="w-full py-0 text-left text-base bg-white rounded-md focus:outline-none"
                  >
                    {selectedCoin}
                    <span className="float-right absolute right-2">
                      <IconCaretDownFilled className="size-5 " />
                    </span>
                  </button>

                  {isPair && (
                    <div className="absolute z-10 right-0 w-full bg-white border border-gray-300 text-black text-sm text-opacity-70 rounded-md shadow-lg">
                      <button
                        onClick={() => {
                          if (amount && amount > 0) {
                            setAmount(+amount / +activePair?.currentPrice);
                          }
                          setSelectedCoin(activePair?.fromCoin?.symbol);
                          setIsPair(false);
                        }}
                        className="w-full px-4 py-2 text-left hover:bg-gray-100"
                      >
                        {activePair?.fromCoin?.symbol}
                      </button>
                      <button
                        onClick={() => {
                          if (amount && amount > 0) {
                            setAmount(+amount * +activePair?.currentPrice);
                          }
                          setSelectedCoin(activePair?.toCoin?.symbol);
                          setIsPair(false);
                        }}
                        className="w-full px-4 py-2 text-left hover:bg-gray-100"
                      >
                        {activePair?.toCoin?.symbol}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-center gap-2 w-[95%] mx-auto mt-2">
                <button
                  onClick={() => {
                    let newBal =
                      bal?.spot && bal?.spot?.length > 0
                        ? bal?.spot?.find(
                            (obj) => obj?.coin?.symbol === selectedCoin
                          )
                        : 0;

                    setAmount(newBal?.amount * 0.25);
                  }}
                  className="rounded-md bg-gray-400 text-white text-sm font-bold w-1/4"
                >
                  25%
                </button>
                <button
                  onClick={() => {
                    let newBal =
                      bal?.spot && bal?.spot?.length > 0
                        ? bal?.spot?.find(
                            (obj) => obj?.coin?.symbol === selectedCoin
                          )
                        : 0;

                    setAmount(newBal?.amount * 0.5);
                  }}
                  className="rounded-md bg-gray-400 text-white text-sm font-bold w-1/4"
                >
                  50%
                </button>
                <button
                  onClick={() => {
                    let newBal =
                      bal?.spot && bal?.spot?.length > 0
                        ? bal?.spot?.find(
                            (obj) => obj?.coin?.symbol === selectedCoin
                          )
                        : 0;

                    setAmount(newBal?.amount * 0.75);
                  }}
                  className="rounded-md bg-gray-400 text-white text-sm font-bold w-1/4"
                >
                  75%
                </button>
                <button
                  onClick={() => {
                    let newBal =
                      bal?.spot && bal?.spot?.length > 0
                        ? bal?.spot?.find(
                            (obj) => obj?.coin?.symbol === selectedCoin
                          )
                        : 0;

                    setAmount(newBal?.amount);
                  }}
                  className="rounded-md bg-gray-400 text-white text-sm font-bold w-1/4"
                >
                  {getLocale("max")}
                </button>
              </div>
              <button
                onClick={handleOrder}
                className={`rounded-md w-[95%] mx-auto mt-5 p-2 text-white font-bold ${
                  isBuyActive ? "bg-green-500" : "bg-red-500"
                }`}
              >
                {isBuyActive ? "Buy" : "Sell"} {activePair?.fromCoin?.symbol}
              </button>
            </div>
          </div>
        </>
      </PopUp>
      <PopUp open={wopen} setisLite={setWopen}>
        <div className="flex flex-col h-[75vh] max-h-[75vh] items-center border bg-white rounded-t-2xl">
          <div className="flex items-center justify-between w-full border-b p-2">
            <h2 className="text-lg font-bold">Transfer</h2>
            <IconX
              onClick={() => setWopen(!wopen)}
              className="rounded-full cursor-pointer border-2 p-1 size-6"
            />
          </div>
          <div className="h-full mx-auto overflow-y-auto w-[95%]">
            <div className="mt-4">
              <div className="mt-2 flex justify-center gap-4 w-full items-center bg-gray-300 rounded-md">
                <div className="w-5/6">
                  <div className="flex justify-start gap-6 items-center rounded-lg px-3 py-2 transition-all duration-200">
                    <label className="text-[#111] opacity-50 font-normal w-[7%] text-left">
                      {/* From */}
                      {getLocale("from")}
                    </label>
                    <label className="text-[#111] font-normal flex items-center gap-2 justify-start">
                      <img
                        src={activeTabTransfer?.from === "Funding" ? w1 : w2}
                        className="size-4"
                      />
                      {activeTabTransfer?.from} Account
                    </label>
                  </div>

                  <IconArrowNarrowDown className="ml-3" />
                  <div className="flex justify-start gap-6 items-center rounded-lg px-4 py-2 transition-all duration-200">
                    <label className="text-[#111] opacity-50 font-normal w-[7%] text-left">
                      {/* To */}
                      {getLocale("to")}
                    </label>
                    <label className="text-[#111] font-normal flex items-center gap-2 justify-start">
                      <img
                        src={activeTabTransfer?.to === "Funding" ? w1 : w2}
                        className="size-4"
                      />
                      {activeTabTransfer?.to} Account
                    </label>
                  </div>
                </div>
                <div className="w-1/6">
                  {" "}
                  <IconArrowsExchange
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveTabTransfer({
                        from: activeTabTransfer?.to,
                        to: activeTabTransfer?.from,
                      });
                      setSelectedCoinTr();
                    }}
                    className="size-7 cursor-pointer rounded-full border rotate-90 hover:bg-white border-[#111] p-1"
                  />
                </div>
              </div>
              <label className="text-[#111] opacity-80 mt-2 text-sm text-left block">
                Coin
              </label>
              <div className="relative inline-block mt-1 mx-auto w-full">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(!isOpen);
                  }}
                  className={`w-full px-4 py-2 text-left bg-white border ${
                    errTr?.coin ? "border-red-500" : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none flex justify-start items-center gap-2 relative`}
                >
                  {selectedCoinTr ? (
                    <>
                      {" "}
                      <img
                        src={selectedCoinTr?.coin?.image}
                        className="size-6 rounded-full"
                        alt=""
                      />
                      <label> {selectedCoinTr?.coin?.name} </label>
                    </>
                  ) : (
                    <label>
                      {/* No Selected Coin */}
                      {getLocale("noCoin")}
                    </label>
                  )}
                  <span className="absolute right-4 rotate-180">^</span>
                </button>

                {isOpen && (
                  <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg text-sm">
                    {activeTabTransfer?.from === "Trading" ? (
                      bal?.spot && bal?.spot?.length > 0 ? (
                        bal?.spot?.map((obj) => {
                          return (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedCoinTr(obj);
                                setIsOpen(false);
                                setErrTr({ ...err, coin: false });
                              }}
                              className="w-full px-4 py-2 text-left hover:bg-gray-100 flex justify-start items-center gap-2"
                              key={obj?._id}
                            >
                              <img
                                src={obj?.coin?.image}
                                className="size-6 rounded-full"
                                alt=""
                              />
                              <label> {obj?.coin?.name}</label>
                            </button>
                          );
                        })
                      ) : (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setErrTr({ ...err, coin: false });
                          }}
                          className="w-full px-4 py-2 text-left hover:bg-gray-100"
                        >
                          {/* You don't have any coin in Trading */}
                          {getLocale("noCoinInSpot")}
                        </button>
                      )
                    ) : bal?.funding && bal?.funding?.length > 0 ? (
                      bal?.funding?.map((obj) => {
                        return (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedCoinTr(obj);
                              setIsOpen(false);
                              setErrTr({ ...err, coin: false });
                            }}
                            className="w-full px-4 py-2 text-left hover:bg-gray-100 flex justify-start items-center gap-2"
                            key={obj?._id}
                          >
                            <img
                              src={obj?.coin?.image}
                              className="size-6 rounded-full"
                              alt=""
                            />
                            <label> {obj?.coin?.name}</label>
                          </button>
                        );
                      })
                    ) : (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setErrTr({ ...err, coin: false });
                        }}
                        className="w-full px-4 py-2 text-left hover:bg-gray-100"
                      >
                        {/* You don't have any coin in Funding wallet */}
                        {getLocale("noCoinInFunding")}
                      </button>
                    )}
                  </div>
                )}
              </div>
              <div className="flex justify-between items-center w-full mt-2">
                <label className="text-[#111] opacity-80 text-sm text-left block">
                  {/* Amount */}
                  {getLocale("amount")}
                </label>
                <label className="text-[#111] opacity-80 text-sm text-left block">
                  Bal.&nbsp;
                  <span className="font-bold">
                    {" "}
                    {selectedCoinTr
                      ? formatValue(+selectedCoinTr?.amount)
                      : "0"}
                    &nbsp;
                    {selectedCoinTr ? selectedCoinTr?.coin?.symbol : ""}
                  </span>
                </label>
              </div>
              <div className="flex justify-start gap-6 items-center border border-[#d1d1d1] rounded-lg shadow-sm px-4 py-2 my-1 transition-all duration-200 relative">
                <input
                  type="number"
                  placeholder={getLocale("amount")}
                  value={amountTr}
                  max={selectedCoinTr ? selectedCoinTr?.amount : false}
                  onChange={(e) => {
                    e.stopPropagation();
                    setAmountTr(Number(e?.target?.value));
                    setErrTr({ ...err, amountTr: false });
                    if (
                      selectedCoinTr &&
                      e?.target?.value > selectedCoinTr?.amount
                    ) {
                      setAmountTr(selectedCoinTr?.amount);
                    } else {
                      setAmountTr(e?.target?.value);
                    }
                  }}
                  className="w-full pr-10 text-lg text-left focus:outline-none"
                />
                <div className="absolute right-4 flex items-start justify-end gap-4">
                  {" "}
                  <label className="text-[#395682] opacity-80 text-sm text-left block">
                    {selectedCoinTr?.coin?.symbol}
                  </label>
                  <label
                    onClick={(e) => {
                      e.stopPropagation();
                      setAmountTr(
                        selectedCoinTr ? selectedCoinTr?.amount : null
                      );
                    }}
                    className="text-[#111] opacity-80 text-sm text-left block cursor-pointer"
                  >
                    {/* Max */}
                    {getLocale("max")}
                  </label>
                </div>
              </div>
              <label className="text-[#111] opacity-80 text-sm text-left block cursor-pointer mt-2">
                {/* Available  */}
              </label>
              <button
                className={`rounded-lg bg-[#184067] w-full cursor-pointer py-3 mt-4 text-white font-medium disabled:cursor-not-allowed ${
                  !amountTr || !selectedCoinTr || amountTr <= 0
                    ? "bg-opacity-45"
                    : "bg-opacity-100"
                }`}
                disabled={
                  !amountTr || amountTr <= 0 || !selectedCoinTr || loaderAdd
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleTransfer();
                }}
              >
                {loaderAdd ? "Loading..." : "Confirm Transfer"}
              </button>
            </div>
          </div>
        </div>
      </PopUp>
    </div>
  );
};

export default BottomDrawer;

export function PopUp({ children, open, setisLite }) {
  return (
    <div
      style={{
        position: "fixed",
        // maxWidth: 425,
        // left:'min(50%,425px)'
        // left: '50%',
        // transform: `translateX(-50 %)`
      }}
      // onClick={(e) => {
      //   setisLite(false);
      // }}
      className={` z-50 ${
        open ? "h-[100%]" : "h-0"
      } transition-all  flex flex-col justify-end bottom-0 overflow-hidden absolute w-full max-w-[425px] mx-auto shadow-2xl bg-black bg-opacity-65`}
    >
      <div>{children}</div>
    </div>
  );
}
