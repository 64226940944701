import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import successLofo from "../../Assets/image/successRegister.png";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";

const SuccessRegister = () => {
  const navigate = useNavigate();
  return (
    <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col justify-between">
      <div className=" flex flex-col justify-center items-center pt-[60px]">
        <img src={successLofo} alt="success" />
        <div className="mt-5">
          <h2 className="text-[#111111] text-[22px] space-x-[-1px] text-center">
            {/* All set */}
            {getLocale("allSet")}
          </h2>
          <p className="text-[#707070] mt-4 text-center">
            {/* Congratulations! Your account has been successfully created. */}
            {getLocale("allSetInfo")}
          </p>
        </div>
      </div>
      <button
        onClick={() => navigate(ROUTES.LANDING)}
        className="w-full p-4 bg-bgBlue text-white rounded-lg mt-10 text-base"
      >
        {/* Finish */}
        {getLocale("finish")}
      </button>
    </div>
  );
};

export default SuccessRegister;
