import {
  IconAlarm,
  IconArrowNarrowLeft,
  IconChartBar,
  IconColumns3,
  IconDiamond,
  IconFileDescription,
  IconHome,
  IconStarFilled,
  IconSticker2,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { getLocale } from "../../constants/language";

import { Link, useNavigate } from "react-router-dom";
import basket from "../../Assets/image/basket.png";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { getAllTransaction, getGraph, getOrderBook } from "../../service/api";
import { useSelector } from "react-redux";
import { ROUTES } from "../../constants/routes";
import { Danger } from "../Core/Errors";
import NotradeModal from "./NotradeModal";
import { formatValue } from "../../service/utils";

function CryptoInfo() {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isInfo, setIsInfo] = useState(false);
  const [warn, setWarn] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [tradeData, setTradeData] = useState([]);
  const [timeFrame, setTimeFrame] = useState("1D");
  const [tab, setTab] = useState("info");
  const [slider, setSlider] = useState({ buy: 0, sell: 0 });
  const { activePair } = useSelector((state) => state.user);

  const getGraphData = () => {
    const currentDate = moment();
    let startDate;

    if (timeFrame === "1H") {
      startDate = moment().subtract(1, "hour");
    } else if (timeFrame === "1D") {
      startDate = moment().subtract(1, "day");
    } else if (timeFrame === "1W") {
      startDate = moment().subtract(1, "week");
    } else if (timeFrame === "1M") {
      startDate = moment().subtract(1, "month");
    } else if (timeFrame === "1Y") {
      startDate = moment().subtract(1, "year");
    } else {
      startDate = currentDate;
    }
    let params = {
      pairId: activePair?._id,
      //   pairId: "670ea181d96301f59e3ffb7e",
    };

    if (timeFrame !== "All") {
      params.startDate = startDate;
      params.endDate = currentDate;
    }

    getGraph(params).then((res) => {
      if (res?.status) {
        const prices =
          res?.data && res?.data?.length > 0
            ? res?.data?.map((item) => item.price)
            : [
                +activePair?.currentPrice - +activePair?.currentPrice * 0.8,
                +activePair?.currentPrice + +activePair?.currentPrice * 0.6,
                +activePair?.currentPrice,
              ];
        const labels =
          res?.data && res?.data?.length > 0
            ? res?.data?.map((item) =>
                moment(item.createdAt).format("DD-MM-YYYY hh:mm A")
              )
            : [
                moment().subtract(10, "minute"),
                moment().subtract(1, "minute"),
                moment(new Date()).format("DD-MM-YYYY hh:mm A"),
              ];
        setChartData(prices);
        setChartLabels(labels);
      }
    });
  };

  useEffect(() => {
    if (activePair) {
      getGraphData();
    }
  }, [activePair, timeFrame]);

  const options = {
    chart: {
      type: "line",
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      title: {
        text: "Month",
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      x: {
        formatter: function (value) {
          return chartLabels[value];
        },
      },
      y: {
        formatter: function (value) {
          return `Price: ${value?.toFixed(2)}`;
        },
      },
      y: {
        title: {
          formatter: () => "",
        },
      },
    },
  };

  const series = [
    {
      data: chartData,
    },
  ];

  const orderBookData = () => {
    let params = {
      pairId: activePair?._id,
      value: 0.1,
    };
    getOrderBook(params)?.then((res) => {
      if (res?.status) {
        setOrderData(res?.data);
        let buy =
          res?.data?.length > 0
            ? res?.data[0]?.buySide?.reduce(
                (acc, crr) => acc + +crr?.totalQuantity,
                0
              )
            : 0;
        let sell =
          res?.data?.length > 0
            ? res?.data[0]?.sellSide?.reduce(
                (acc, crr) => acc + +crr?.totalQuantity,
                0
              )
            : 0;

        let total = buy + sell;

        if (total > 0) {
          setSlider({
            buy: (buy / total) * 100,
            sell: (sell / total) * 100,
          });
        } else {
          setSlider({ buy: 0, sell: 0 });
        }
      }
    });
  };

  const getTradeData = () => {
    let params = {
      pairId: activePair?._id,
    };
    getAllTransaction(params)
      ?.then((res) => {
        if (res?.status) {
          setTradeData(res?.data?.result);
        }
      })
      .catch((e) => {
        console.log("%c Line:145 🥔 e", "color:#ed9ec7", e);
      });
  };

  useEffect(() => {
    orderBookData();
    getTradeData();
  }, []);

  return (
    <div>
      <NotradeModal warn={warn} setWarn={setWarn} />
      <div className="h-full min-h-[100vh] bg-white font-sans px-4 py-12 flex flex-col relative">
        <div className=" flex justify-between items-center">
          <IconArrowNarrowLeft
            className="size-7 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h1 className="font-medium leading-[140%] tracking-[-1px] ">
            {activePair?.fromCoin?.symbol}
            {activePair?.toCoin?.symbol}
            <br />${activePair?.currentPrice}
          </h1>
          <IconStarFilled size={15} />
        </div>
        <div className="m-3">
          {chartData && chartData?.length > 0 ? (
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              // height={350}
            />
          ) : (
            <div className="bg-transparent border border-gray-600 rounded-lg p-4 w-full h-[229px] mx-auto text-black font-medium text-lg">
              {/* No graph data found */}
              {getLocale("noGraph")}
            </div>
          )}
        </div>
        <div className="flex justify-around items-center mt-2">
          <div
            className={`text-base ${
              timeFrame === "1H" ? "text-[#26a0fc]" : "text-[#1f1f1fab]"
            } font-medium mt-2 cursor-pointer`}
            onClick={() => setTimeFrame("1H")}
          >
            1H
          </div>
          <div
            className={`text-base ${
              timeFrame === "1D" ? "text-[#26a0fc]" : "text-[#1f1f1fab]"
            } font-medium mt-2 cursor-pointer`}
            onClick={() => setTimeFrame("1D")}
          >
            1D
          </div>
          <div
            className={`text-base ${
              timeFrame === "1W" ? "text-[#26a0fc]" : "text-[#1f1f1fab]"
            } font-medium mt-2 cursor-pointer`}
            onClick={() => setTimeFrame("1W")}
          >
            1W
          </div>
          <div
            className={`text-base ${
              timeFrame === "1M" ? "text-[#26a0fc]" : "text-[#1f1f1fab]"
            } font-medium mt-2 cursor-pointer`}
            onClick={() => setTimeFrame("1M")}
          >
            1M
          </div>
          <div
            className={`text-base ${
              timeFrame === "1Y" ? "text-[#26a0fc]" : "text-[#1f1f1fab]"
            } font-medium mt-2 cursor-pointer`}
            onClick={() => setTimeFrame("1Y")}
          >
            1Y
          </div>
          <div
            className={`text-base ${
              timeFrame === "All" ? "text-[#26a0fc]" : "text-[#1f1f1fab]"
            } font-medium mt-2 cursor-pointer`}
            onClick={() => setTimeFrame("All")}
          >
            {/* All */}
            {getLocale("all")}
          </div>
        </div>
        <button
          onClick={() => {
            if (user?.kycStatus !== "APPROVED") {
              // Danger(getLocale("kycMsg"));
              setWarn(true);
            } else {
              navigate(ROUTES.BUYPAIR);
            }
          }}
          className="w-full p-4 bg-bgBlue text-white rounded-lg mt-8 text-base"
        >
          {/* Buy/Sell */}
          {getLocale("buySell")}
        </button>
        <div className=" border border-[#CFCFCF] rounded-md px-4 py-4  mt-5">
          <h2 className="text-[22px] font-medium text-[#111111] leading-[145%] tracking-[-1px] text-left">
            {/* When is the best time to buy? */}
            {getLocale("timeToBuy")}
          </h2>
          <div className=" flex justify-between items-start">
            <p className="text-[16px] font-normal text-[#707070] leading-[140%] tracking-[0.2px] text-left w-[70%]">
              {/* It is difficult to predict the best time to invest, which is why
              many use dollar-cost averaging. */}
              {getLocale("timeToBuyInfo")}
            </p>
            <div className="w-[20%]">
              <img src={basket} alt="" />
            </div>
          </div>
        </div>
        <div className="mt-3 hidden">
          <h2 className="text-[22px] font-medium text-[#111111] leading-[145%] tracking-[-1px] text-left">
            {/* About USDT */}
            {getLocale("aboutUSDT")}
          </h2>
          <p className="text-[16px] font-normal text-[#707070] leading-[140%] tracking-[0.2px] text-left">
            {/* USDT is a stable cryptocurrency pegged to the value of the US
            dollar, used to facilitate fast and secure transactions in the
            crypto world. */}
            {getLocale("aboutUSDTInfo")}
          </p>
        </div>
        <div className="mt-5 hidden">
          <h2 className="text-[22px] font-medium text-[#111111] leading-[145%] tracking-[-1px] text-left">
            {/* Resources */}
            {getLocale("resources")}
          </h2>
          <div>
            <div className=" flex gap-3 mt-2">
              <IconFileDescription color="#707070" size={20} />
              <Link className="text-[16px] font-normal text-[#2752E7] leading-[140%] tracking-[0.2px] text-left">
                {/* Sitio Web Oficial */}
                {getLocale("webOfficial")}
              </Link>
            </div>
            <div className=" flex gap-3 mt-2">
              <IconSticker2 color="#707070" size={20} />
              <Link className="text-[16px] font-normal text-[#2752E7] leading-[140%] tracking-[0.2px] text-left">
                {/* Whitepaper */}
                {getLocale("whitePaper")}
              </Link>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-start gap-3 my-2 border-b border-gray-800 text-sm mt-4">
          <div
            onClick={() => setTab("info")}
            className={`border-b-2 pb-1 ${
              tab === "info"
                ? "font-semibold border-gray-800"
                : "font-medium border-transparent"
            } cursor-pointer`}
          >
            {/* Info */}
            {getLocale("info")}
          </div>
          <div
            onClick={() => setTab("order")}
            className={`border-b-2 pb-1 ${
              tab === "order"
                ? "font-semibold border-gray-800"
                : "font-medium border-transparent"
            } cursor-pointer`}
          >
            {/* Order Book */}
            {getLocale("orderBook")}
          </div>
          <div
            onClick={() => setTab("trade")}
            className={`border-b-2 pb-1 ${
              tab === "trade"
                ? "font-semibold border-gray-800"
                : "font-medium border-transparent"
            } cursor-pointer`}
          >
            {/* Trades */}
            {getLocale("trades")}
          </div>
        </div>

        {tab === "info" && (
          <div className="mt-3">
            <h2 className="text-[22px] font-medium text-[#111111] leading-[145%] tracking-[-1px] text-left">
              {/* Market statistics */}
              {getLocale("marketStatistics")}
            </h2>
            <div>
              <div
                onClick={() => setIsInfo(true)}
                className=" flex gap-3 mt-2 justify-between items-center"
              >
                <div className=" flex gap-3  items-center">
                  <IconHome color="#6DA8FF" size={20} />
                  <Link className="text-[16px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                    {/* Market Cap */}
                    {getLocale("marketCap")}
                  </Link>
                </div>
                <p className="text-[16px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                  $723.6 billion
                </p>
              </div>

              <div className=" flex gap-3 mt-5 justify-between items-center">
                <div className=" flex gap-3  items-center">
                  <IconColumns3 color="#6DA8FF" size={20} />
                  <Link className="text-[16px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                    {/* Volumen  */}
                    {getLocale("volumen")}
                    {activePair?.fromCoin?.name}
                  </Link>
                </div>
                <p className="text-[16px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                  {activePair?.fromCoinVolume}
                </p>
              </div>
              <div className=" flex gap-3 mt-5 justify-between items-center">
                <div className=" flex gap-3  items-center">
                  <IconColumns3 color="#6DA8FF" size={20} />
                  <Link className="text-[16px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                    {/* Volumen  */}
                    {getLocale("volumen")} {activePair?.toCoin?.name}
                  </Link>
                </div>
                <p className="text-[16px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                  {activePair?.toCoinVolume}
                </p>
              </div>
              <div className=" flex gap-3 mt-5 justify-between items-center">
                <div className=" flex gap-3  items-center">
                  <IconColumns3 color="#6DA8FF" size={20} />
                  <Link className="text-[16px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                    {/* Oferta circulante */}
                    {getLocale("oferta")}
                  </Link>
                </div>
                <p className="text-[16px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                  $35.6 billion
                </p>
              </div>
              <div className=" flex gap-3 mt-5 justify-between items-center">
                <div className=" flex gap-3  items-center">
                  <IconChartBar color="#6DA8FF" size={20} />
                  <Link className="text-[16px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                    {/* Actividad comercial */}
                    {getLocale("actComercial")}
                  </Link>
                </div>
                <p className="text-[16px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                  $36.6% {getLocale("sell")}
                </p>
              </div>
              <div className=" flex gap-3 mt-5 justify-between items-center">
                <div className=" flex gap-3  items-center">
                  <IconAlarm color="#6DA8FF" size={20} />
                  <Link className="text-[16px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                    {/* Tiempo típico de retención. */}
                    {getLocale("retentionTime")}
                  </Link>
                </div>
                <p className="text-[16px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                  53 {getLocale("days")}
                </p>
              </div>
              <div className=" flex gap-3 mt-5 justify-between items-center">
                <div className=" flex gap-3  items-center">
                  <IconDiamond color="#6DA8FF" size={20} />
                  <Link className="text-[16px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                    {/* Popularidad en KMBIO. */}
                    {getLocale("popularity")}
                  </Link>
                </div>
                <p className="text-[16px] font-normal text-[#111111] leading-[140%] tracking-[0.2px] text-left">
                  #3
                </p>
              </div>
            </div>
          </div>
        )}

        {tab === "order" && (
          <div className="mt-3">
            <div className="flex items-center justify-center w-full gap-2">
              <p className="text-sm mb-0 text-green-500">
                {formatValue(slider?.buy)}%
              </p>
              <input
                type="range"
                min="0"
                max="100"
                value={slider?.buy}
                className="slider-thumb h-1.5 w-full rounded-xl appearance-none bg-gray-300"
                style={{
                  background: `linear-gradient(to right, green ${slider?.buy}%, red ${slider?.sell}%)`,
                }}
              />
              <p className="text-sm mb-0 text-red-500">
                {formatValue(slider?.sell)}%
              </p>
            </div>
            <div className="flex items-start justify-center w-full gap-2">
              <div className="flex w-1/2 flex-col items-center justify-start">
                <div className="flex items-start justify-between w-full mb-1">
                  <p className="text-sm mb-0 text-black">Bid</p>
                  <p></p>
                </div>
                {orderData?.length > 0 &&
                  orderData[0] &&
                  orderData[0]?.buySide?.length > 0 &&
                  orderData[0]?.buySide?.map((obj, index) => {
                    return (
                      <div
                        key={index}
                        className="flex items-start justify-between w-full"
                      >
                        <p className="text-xs mb-0 text-black">
                          {formatValue(obj?.totalQuantity)}
                        </p>
                        <p className="text-xs mb-0 text-green-500">
                          {obj?._id}
                        </p>
                      </div>
                    );
                  })}
              </div>
              <div className="flex w-1/2 flex-col items-center justify-start">
                <div className="flex items-start justify-between w-full mb-1">
                  <p className="text-sm mb-0 text-black">Ask</p>
                  <p></p>
                </div>
                {orderData?.length > 0 &&
                  orderData[0] &&
                  orderData[0]?.sellSide?.length > 0 &&
                  orderData[0]?.sellSide?.map((obj, index) => {
                    return (
                      <div
                        key={index}
                        className="flex items-start justify-between w-full"
                      >
                        <p className="text-xs mb-0 text-red-500">{obj?._id}</p>
                        <p className="text-xs mb-0 text-black">
                          {formatValue(obj?.totalQuantity)}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}

        {tab === "trade" && (
          <div className="mt-3 w-full ">
            <div className="flex justify-between items-center w-full mb-2">
              <p className="text-xs mb-0 text-black">Time</p>
              <p className="text-xs mb-0 text-black">Price</p>
              <p className="text-xs mb-0 text-black">Amount</p>
            </div>
            {tradeData && tradeData?.length > 0 ? (
              tradeData?.map((obj, index) => {
                return (
                  <div
                    key={index}
                    className="flex justify-between items-center w-full"
                  >
                    <p className="text-xs mb-0 text-black">
                      {moment(obj?.createdAt).format("HH:mm:ss")}
                    </p>
                    <p
                      className={`text-xs mb-0 text-left 
                        ${!obj?.buyOrderId ? "text-green-500" : "text-red-500"}
                      `}
                    >
                      {obj?.price}
                    </p>
                    <p className="text-xs mb-0 text-black">{obj?.quantity}</p>
                  </div>
                );
              })
            ) : (
              <div className="text-xs mt-2 text-black">No trades found</div>
            )}
          </div>
        )}
        <div
          onClick={(e) => {
            e.stopPropagation();
            setIsInfo(!isInfo);
          }}
          className={`${
            isInfo ? "h-full" : "h-0"
          } transition-all w-full bg-[#111111]/[0.2] absolute left-0 bottom-0  flex justify-end flex-col overflow-hidden`}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className=" px-4 py-12 min-h-[300px] bg-white bottom-0 left-0 rounded-tl-[20px] rounded-tr-[20px] flex flex-col justify-between"
          >
            <div>
              <h1 className="text-[22px] font-medium text-[#111111] leading-[145%] tracking-[-1px] text-left">
                {/* Volume */}
                {getLocale("volume")}
              </h1>
              <p className="text-[16px] font-normal text-[#707070] leading-[140%] tracking-[0.2px] text-left">
                {/* The total dollar value of all BTC transactions in the last 24
                hours. This includes data from all exchanges, not just KMBIO. */}
                {getLocale("volumeInfo")}
              </p>
            </div>
            <button className="w-full p-4 bg-white text-[#111111] rounded-lg mt-8 text-base border border-md border-[#CFCFCF]">
              {/* More information */}
              {getLocale("moreInfo")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CryptoInfo;
