import React, { useEffect, useState } from "react";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { CustomInput } from "../Core/input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  generate2FA,
  getUserProfile,
  googleLogin,
  login,
  resendEmail,
  resendMobile,
  userFireBaseToken,
  verify2FA,
  verifyEmail,
  verifyMobile,
} from "../../service/api";
import { CircularProgress } from "@mui/material";
import actions from "../../redux/user/actions";
import { Danger } from "../Core/Errors";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";
import logo from "../../Assets/image/new-logo.png";
import { useTranslation } from "react-i18next";
import google from "../../Assets/image/dedfe0e901fb83a6google.svg";
import OTPInput from "react-otp-input";
import QRCode from "react-qr-code";
import Loader from "../Core/Loader";

const validationSchema = yup.object().shape({
  password: yup.string().required("Password is required"),
  email: yup.string().required("Phone Number/Email is required."),
});
const Login = () => {
  const fromLogin = useLocation()?.state?.email;
  const { language, user } = useSelector((state) => state?.user);
  const firebaseToken = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState("stepOne");
  const [authCode, setAuthCode] = useState();
  const [authCodeErr, setAuthCodeErr] = useState(false);
  const [otp, setOtp] = useState();
  const [string, setString] = useState("");
  const [otpErr, setOtpErr] = useState(false);
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [next, setNext] = useState(false);
  const [nextScreen, setNextScreen] = useState(false);
  const [phoneNumberErr, setPhoneNumberErr] = useState(false);
  const { i18n } = useTranslation();
  const { search } = useLocation();
  const url = new URL(window.location.href);

  useEffect(() => {
    if (url.searchParams.get("token")) {
      dispatch(actions.setToken(url.searchParams.get("token")));
      sessionStorage.setItem("token", url.searchParams.get("token"));
      setLoading(true);
      getUserProfile()
        .then((res) => {
          if (res?.status) {
            dispatch(actions.setUser(res?.data));
            if (res?.data?.authenticatorVerified) {
              setStep("stepTwo");
            } else {
              generateQR();
            }
            // if (url.searchParams.get("type") === "login") {
            //   navigate(ROUTES.LANDING);
            // } else {
            //   navigate(`${ROUTES.LANDING}?kyc`);
            // }
          }
        })
        .finally(() => setLoading(true));
    } else if (url.searchParams.get("error")) {
      Danger("No any profile found");
    }
  }, [search]);

  // useEffect(() => {
  //   if (search?.split("?token=")[1]) {
  //     dispatch(actions.setToken(search?.split("?token=")[1]));
  //     sessionStorage.setItem("token", search?.split("?token=")[1]);
  //     navigate(ROUTES.LANDING)
  //     // getProfile();
  //   }
  // }, [search]);

  useEffect(() => {
    if (fromLogin) {
      formik.setFieldValue("email", fromLogin);
      setNext(true);
    }
  }, [fromLogin]);

  const FireBaseToken = () => {
    userFireBaseToken({ token: firebaseToken })
      .then((res) => {})
      .catch((e) => {});
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      login(values)
        .then((res) => {
          if (res?.status) {
            if (firebaseToken) {
              FireBaseToken();
            }
            sessionStorage.setItem("token", res?.data?.token);

            // if (
            //   res?.data?.userData?.kycStatus === "PENDING" ||
            //   res?.data?.userData?.kycStatus === "IN-PROCESS"
            // ) {
            //   if (res?.data?.userData?.kycLevel == 1) {
            //     navigate(ROUTES.SECURITY_ID);
            //   } else if (res?.data?.userData?.kycLevel == 2) {
            //     navigate(ROUTES.S_PHOTO_ID);
            //   } else if (res?.data?.userData?.kycLevel == 3) {
            //     navigate(ROUTES.ADDRESS);
            //   } else if (res?.data?.userData?.kycLevel == 4) {
            //     navigate(ROUTES.PURPOSE);
            //   } else if (res?.data?.userData?.kycLevel == 5) {
            //     navigate(ROUTES.EXPECTATIONS);
            //   } else if (res?.data?.userData?.kycLevel == 6) {
            //     navigate(ROUTES.LANDING);
            //   } else {
            //     navigate(ROUTES.PERSONAL_INFO);
            //   }
            // } else {
            // navigate(ROUTES.LANDING);
            // }

            if (res?.data?.userData?.authenticatorVerified) {
              setStep("stepTwo");
            } else {
              generateQR();
            }
            dispatch(actions.setToken(res?.data?.token));
            dispatch(actions.setUser(res?.data?.userData));
            dispatch(actions.setLoggedIn(true));
          } else {
            Danger(res?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
        })
        .finally((res) => {
          setLoading(false);
        });
    },
  });

  const generateQR = () => {
    setLoading(true);
    generate2FA()
      ?.then((res) => {
        if (res?.status) {
          setString(res?.data?.secret);
          setStep("stepFour");
        }
      })
      .finally(() => setLoading(false));
  };
  const sendOTP = () => {
    if (!formik?.values?.email) {
      setPhoneNumberErr(true);
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setLoading(true);
    const API = emailPattern.test(formik?.values?.email)
      ? resendEmail
      : resendMobile;
    let body = {};
    if (emailPattern.test(formik?.values?.email)) {
      body.email = formik?.values?.email;
    } else {
      body.mobile = formik?.values?.email;
    }
    API(body)
      ?.then((res) => {
        if (res?.status) {
          setNextScreen(true);
        }
      })
      .finally(() => setLoading(false));
  };

  const verifyCode = () => {
    if (!otp || otp?.length !== 6) {
      setOtpErr(true);
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setLoading(true);
    const API = emailPattern.test(formik?.values?.email)
      ? verifyEmail
      : verifyMobile;
    let body = {
      code: otp,
    };
    if (emailPattern.test(formik?.values?.email)) {
      body.email = formik?.values?.email;
    } else {
      body.mobile = formik?.values?.email;
    }
    API(body)
      ?.then((res) => {
        if (res?.status) {
          // setNextScreen(true);
          generateQR();
          setStep("stepFour");
        }
      })
      .finally(() => setLoading(false));
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const loginGoogle = () => {
    setLoadingGoogle(true);
    googleLogin()
      ?.then((res) => {
        if (res?.status) {
          window.location.replace(res?.data);
        }
      })
      .catch((e) => {
        console.log("%c Line:103 🍻 e", "color:#ed9ec7", e);
      })
      .finally(() => setLoadingGoogle(false));
  };

  const twoFAVerify = () => {
    if (!/^\d{6}$/.test(authCode)) {
      setAuthCodeErr(getLocale("authLength"));
      return;
    }
    setLoading(true);
    let body = { code: authCode };
    verify2FA(body)
      .then((res) => {
        if (res?.status) {
          navigate(ROUTES.LANDING);
          setStep("stepOne");
          setAuthCodeErr(false);
          setAuthCode();
          setOtp();
          setOtpErr(false);
          formik?.resetForm();
        } else {
          setAuthCodeErr(res?.message);
        }
      })
      .catch((e) => {
        console.log("%c Line:253 🍔 e", "color:#6ec1c2", e);
        setAuthCodeErr(e?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-6">
      {/* {step === "stepOne" ? (
        <IconX
          className="size-7 cursor-pointer"
          onClick={() => navigate(ROUTES.HOME)}
        />
      ) : (
        <IconArrowLeft
          className="size-7 cursor-pointer"
          onClick={() => setStep("stepOne")}
        />
      )} */}
      {loading && <Loader />}
      <div className="flex items-center justify-between gap-2 p-2 border-b border-[#bcbcbc]">
        <img
          onClick={() => navigate("/")}
          src={logo}
          alt=""
          className="w-[160px] h-[45px"
        />
        <div className="flex items-center justify-center bg-[#101010] opacity-95 w-fit rounded-full p-1">
          <div
            onClick={() => {
              changeLanguage(language === "sp" ? "en" : "es");
              dispatch(actions.setLangauge(language === "sp" ? "en" : "sp"));
            }}
            className={`${
              language === "en"
                ? "bg-white text-black"
                : "bg-transparent text-white"
            } text-xs font-bold p-1 rounded-full cursor-pointer w-[30px]`}
          >
            EN
          </div>
          <div
            onClick={() => {
              changeLanguage(language === "sp" ? "en" : "es");
              dispatch(actions.setLangauge(language === "sp" ? "en" : "sp"));
            }}
            className={`${
              language === "sp"
                ? "bg-white text-black"
                : "bg-transparent text-white"
            } text-xs font-bold p-1 rounded-full cursor-pointer w-[30px]`}
          >
            ES
          </div>
        </div>
      </div>
      {step === "stepOne" && (
        <form className="mt-5" onSubmit={formik.handleSubmit}>
          <div className="flex items-start flex-col w-full mt-6">
            <h2 className=" text-[22px] font-[700] text-textDefault text-left ">
              {getLocale("logInToKMBIO")}
            </h2>
            <div className="form-control w-full mt-8">
              {/* <label className="block text-left text-textDefault text-base font-medium pb-2">
                {getLocale("phoneNumber")}/{getLocale("email")}
              </label> */}
              <CustomInput
                placeholder={`${getLocale("phoneNumber")}/${getLocale(
                  "email"
                )}`}
                name="email"
                id="email"
                value={formik?.values?.email}
                onChange={(e) => {
                  formik.setFieldValue("email", e.target.value);
                  setPhoneNumberErr(false);
                }}
                error={
                  (formik.errors.email && formik.touched.email) ||
                  phoneNumberErr
                    ? true
                    : false
                }
              />
              <label className="block text-left text-textDefault text-sm opacity-60 font-medium mt-2">
                {getLocale("inCase")}
              </label>
            </div>
            {next && (
              <>
                {" "}
                <div className="form-control w-full mt-4">
                  {/* <label className="block text-left text-textDefault text-base font-medium pb-2">
                    {getLocale("password")}
                  </label> */}
                  <div className="relative">
                    <CustomInput
                      type={passwordToggle ? "text" : "password"}
                      placeholder={getLocale("password")}
                      className="w-full pr-10"
                      name="password"
                      id="password"
                      value={formik?.values?.password}
                      onChange={formik.handleChange}
                      error={
                        formik.errors.password && formik.touched.password
                          ? true
                          : false
                      }
                    />
                    {!passwordToggle ? (
                      <IconEye
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer opacity-50"
                        onClick={() => setPasswordToggle(!passwordToggle)}
                      />
                    ) : (
                      <IconEyeOff
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer opacity-50"
                        onClick={() => setPasswordToggle(!passwordToggle)}
                      />
                    )}
                  </div>
                </div>
                <div className="flex justify-between items-center w-full mt-6 text-black text-sm">
                  <label
                    className="cursor-pointer"
                    onClick={() => {
                      navigate(ROUTES.FORGOT_PASSWORD, {
                        state: { email: formik?.values?.email },
                      });
                    }}
                  >
                    {/* <Link to={ROUTES.FORGOT_PASSWORD} className="cursor-pointer"> */}
                    {getLocale("forgotYourPassword")}
                  </label>
                  <label className="cursor-pointer">
                    {/* Privacy Policy */}
                    {getLocale("privacyPolicies")}
                  </label>
                </div>
              </>
            )}

            {loading ? (
              <button
                disabled={loading}
                className="w-full p-2 bg-bgBlue text-white rounded-full mt-6 text-base"
              >
                <CircularProgress size={20} color="white" thickness={6} />{" "}
              </button>
            ) : !next ? (
              <button
                type="button"
                disabled={loading}
                onClick={(e) => {
                  if (!formik?.values?.email) {
                    setPhoneNumberErr(true);
                  } else {
                    e.stopPropagation();
                    e.preventDefault();
                    setPhoneNumberErr(false);
                    setNext(!next);
                  }
                }}
                className="w-full p-2 bg-bgBlue text-white rounded-full mt-6 text-base font-[700]"
              >
                {getLocale("Next")}
              </button>
            ) : (
              <button
                type="submit"
                disabled={loading}
                className="w-full p-2 bg-bgBlue text-white rounded-full mt-6 text-base font-[700]"
              >
                {getLocale("login")}
              </button>
            )}
            <div className="flex items-center justify-center w-full gap-2 mt-8 mb-6">
              <div className="w-1/3 h-[1px] bg-[#bcbcbc] rounded-full"></div>
              <label className="w-fit text-[#bcbcbc] text-sm">
                {getLocale("continueWith")}
              </label>
              <div className="w-1/3 h-[1px] bg-[#bcbcbc] rounded-full"></div>
            </div>
            {loadingGoogle ? (
              <button
                disabled={loadingGoogle}
                className="w-full p-2 bg-bgBlue text-white rounded-full text-base"
              >
                <CircularProgress size={20} color="white" thickness={6} />{" "}
              </button>
            ) : (
              <div
                onClick={loginGoogle}
                className="flex items-center justify-center gap-2 w-full mx-auto rounded-full py-2 px-4 bg-transparent border border-[#bcbcbc] cursor-pointer"
              >
                <img src={google} alt="" className="h-5 w-5" />
                <label className="text-center w-fit text-[#000000] text-base">
                  Google
                </label>
              </div>
            )}
            <label className="text-[#000000] text-sm text-left mt-6">
              {getLocale("don'tAccount")}
              <span
                onClick={() => navigate(ROUTES.REGISTER)}
                className="font-semibold underline cursor-pointer"
              >
                {" "}
                {getLocale("signup")}
              </span>
            </label>
          </div>
        </form>
      )}{" "}
      {step === "stepTwo" && (
        <div className="flex flex-col w-full mt-6 ">
          <h3 className="text-lg font-medium text-textDefault">
            {/* Introduce the 6 digit code that was sent to XXX-XXX-X96 */}
            {getLocale("introduce6DigitCode")}
          </h3>
          <h6 className="text-base mt-2 font-medium text-textDefault opacity-50">
            {/* This extra step helps us verify that it is indeed you who is
            accessing your account. */}
            {getLocale("extraStep")}
          </h6>
          <div className="form-control w-full mt-8">
            <CustomInput
              placeholder={getLocale("authCode")}
              value={authCode}
              onChange={(e) => {
                setAuthCode(e.target.value);
                setAuthCodeErr(false);
              }}
              error={authCodeErr ? true : false}
            />
          </div>
          {authCodeErr && (
            <label className="text-xs text-bold mt-2 text-left text-red-500">
              {authCodeErr}
            </label>
          )}
          <label
            onClick={() => {
              setAuthCodeErr(false);
              setStep("stepThree");
            }}
            className="text-sm text-bold mt-2 text-left cursor-pointer"
          >
            {getLocale("recoverCode")}
          </label>
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 w-full px-4">
            <button
              type="button"
              disabled={loading}
              onClick={() => twoFAVerify()}
              className="w-full mb-4 p-4 bg-bgBlue text-white rounded-lg text-base"
            >
              {/* Confirm */}
              {getLocale("confirm")}
            </button>
            <button className="w-full hidden p-4 bg-white text-bgBlue border rounded-lg text-base">
              {/* I need help */}
              {getLocale("help")}
            </button>
          </div>
        </div>
      )}
      {step === "stepThree" && (
        <div className="flex flex-col w-full mt-6 ">
          <h3 className="text-lg font-medium text-textDefault">
            {getLocale("info1")}
          </h3>
          <div className="form-control w-full mt-8">
            {/* <label className="block text-left text-textDefault text-base font-medium pb-2">
                {getLocale("phoneNumber")}/{getLocale("email")}
              </label> */}
            <CustomInput
              placeholder={`${getLocale("phoneNumber")}/${getLocale("email")}`}
              name="email"
              id="email"
              value={formik?.values?.email}
              onChange={(e) => {
                formik.setFieldValue("email", e.target.value);
                setPhoneNumberErr(false);
              }}
              error={
                (formik.errors.email && formik.touched.email) || phoneNumberErr
                  ? true
                  : false
              }
            />
            <label className="block text-left text-textDefault text-sm opacity-60 font-medium mt-2">
              {getLocale("inCase")}
            </label>
          </div>
          {authCodeErr && (
            <label className="text-xs text-bold mt-2 text-left text-red-500">
              {authCodeErr}
            </label>
          )}
          {nextScreen && (
            <div className="mt-0">
              <OTPInput
                numInputs={6}
                renderSeparator={<p className="text-sm  text-black px-2">-</p>}
                renderInput={(props) => (
                  <input
                    {...props}
                    className={`!w-full h-11 bg-white border ${
                      otpErr ? "border-red-500" : "border-borderGrey"
                    } rounded-md text-lg font-[600] text-black`}
                  />
                )}
                containerStyle="w-full"
                value={otp}
                onChange={(value) => {
                  setOtp(value);
                  setOtpErr(false);
                }}
              />
            </div>
          )}
          <button
            type="button"
            disabled={loading}
            onClick={() => {
              !nextScreen ? sendOTP() : verifyCode();
            }}
            className="w-full mb-4 p-4 mt-2 bg-bgBlue text-white rounded-lg text-base"
          >
            {getLocale("continue")}
          </button>
          <label
            onClick={() => {
              setAuthCodeErr(false);
              setStep("stepTwo");
            }}
            className="text-sm text-bold mt-2 text-left cursor-pointer"
          >
            {getLocale("alCode")}
          </label>
        </div>
      )}
      {step === "stepFour" && (
        <div className="flex flex-col w-full mt-6 ">
          <ol className="ml-5 list-decimal text-left">
            <li className="text-base text-black">{getLocale("intr1")}</li>
            <li className="text-base text-black mt-2">{getLocale("intr2")}</li>
            <li className="text-base text-black mt-2">{getLocale("intr3")}</li>
            <li className="text-base text-black mt-2">{getLocale("intr4")}</li>
          </ol>

          {/* SETUP KEY */}
          <div className="mt-3 flex items-center justify-center">
            <label className="text-md max-w-[90%] text-black ">
              {getLocale("setup")}{" "}
              <span className="text-black break-words whitespace-normal">
                {string}
              </span>
            </label>
          </div>

          <div className="mt-5 flex flex-col items-center mb-4">
            <label className="text-md text-black ">{getLocale("scan")}</label>
            <div className="mt-2 border-2 border-white">
              <QRCode
                value={`otpauth://totp/KMBIO:${encodeURIComponent(
                  `${user?.firstName} ${user?.lastName}`
                )}?secret=${string}&issuer=KMBIO`}
                size={150}
              />
            </div>
          </div>
          <div className="form-control w-full mt-8">
            <CustomInput
              placeholder={getLocale("authCode")}
              value={authCode}
              onChange={(e) => {
                setAuthCode(e.target.value);
                setAuthCodeErr(false);
              }}
              error={authCodeErr ? true : false}
            />
          </div>
          {authCodeErr && (
            <label className="text-xs text-bold mt-2 text-left text-red-500">
              {authCodeErr}
            </label>
          )}
          <button
            type="button"
            disabled={loading}
            onClick={() => twoFAVerify()}
            className="w-full mb-4 mt-2 p-4 bg-bgBlue text-white rounded-lg text-base"
          >
            {/* Confirm */}
            {getLocale("confirm")}
          </button>
        </div>
      )}
    </div>
  );
};

export default Login;
