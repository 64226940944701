import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Login from "../components/pages/Login";
import Register from "../components/pages/Register";
import HomePage from "../components/pages/HomePage";
import Landing from "../components/pages/Landing";
import SecurityInfo from "../components/pages/SecurityInfo";
import SecurityId from "../components/pages/SecurityId";
import SPhotoId from "../components/pages/SPhotoId";
import FaceId from "../components/pages/FaceId";
import PersonalInfo from "../components/pages/PersonalInfo";
import Citizenship from "../components/pages/Citizenship";
import Address from "../components/pages/Address";
import Purpose from "../components/pages/Purpose";
import Expectations from "../components/pages/Expectations";
import SuccessRegister from "../components/pages/SuccessRegister";
import Onboarding from "../components/pages/Onboarding";
import Onboarding1 from "../components/pages/Onboarding1";
import Notifications from "../components/pages/Notifications";
import Refer from "../components/pages/Refer";
import Convert from "../components/pages/Convert";
import Security from "../components/pages/Security";
import SecuritySteps from "../components/pages/SecuritySteps";
import SecurityEmail from "../components/pages/SecurityEmail";
import TwoFA from "../components/pages/TwoFA";
import ConfirmTwoFa from "../components/pages/ConfirmTwoFa";
import ConfirmTwoFaMobile from "../components/pages/ConfirmTwoFaMobile";
import ConfirmTwoFaMobileKyc from "../components/pages/ConfirmTwoFaMobileKyc";
import Recieve from "../components/pages/Recieve";
import General from "../components/pages/General";
import Transaction from "../components/pages/Transaction";
import Coin from "../components/pages/Coin";
import CryptoInfo from "../components/pages/CryptoInfo";
import Profile from "../components/pages/Profile";
import NotificationSetting from "../components/pages/NotificationSetting";
import SelectAssetsToBuy from "../components/pages/SelectAssetsToBuy";
import Buy from "../components/pages/Buy";
import AddPaymentMethod from "../components/pages/AddPaymentMethod";
import OrderSummary from "../components/pages/OrderSummary";
import BuySent from "../components/pages/BuySent";
import OrderStatus from "../components/pages/OrderStatus";
import Market from "../components/pages/Market";
import VerifyEmail from "../components/pages/VerifyEmail";
import { ROUTES } from "../constants/routes";
// import FrogotPassword from "../components/pages/FrogotPassword";
import ChangePassword from "../components/pages/ChangePassword";
import P2PHome from "../components/pages/P2P/P2PHome";
import PostAdForm from "../components/pages/P2P/PostAdForm";
import PaymentMethods from "../components/pages/PaymentMethod/PaymentMethods";
import Recomended from "../components/pages/PaymentMethod/Recomended";
import AddPayMethod from "../components/pages/PaymentMethod/AddPayMethod";
import EditPaymethod from "../components/pages/PaymentMethod/EditPaymethod";
import BuyP2P from "../components/pages/P2P/BuyP2P";
import VerifyOrder from "../components/pages/P2P/VerifyOrder";
// import VerifyOrderToSell from "../components/pages/P2P/ConfirmToSeller";
import MyAdds from "../components/pages/P2P/MyAdds";
import Wallet from "../components/pages/Wallet";
import P2POrders from "../components/pages/P2P/Order";
import SpotBuy from "../components/pages/SpotBuy";
import PairCoin from "../components/pages/PairOrder";
import PairOrderStatus from "../components/pages/PairOrderStatus";
import CoinHistory from "../components/pages/Coin history";
import WithdrawCoin from "../components/pages/WithdrawCoin";
import DepositCoin from "../components/pages/DepositCoin";
import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import ChooseMethod from "../components/pages/ChooseType";
import ForgotNew from "../components/pages/forgotPasswordNew";
import LiteBuy from "../components/pages/Lite/Buy";
import BuyAmount from "../components/pages/Lite/BuyAmount";
import SellAmount from "../components/pages/Lite/SellAmount";
import SelectPaymethod from "../components/pages/Lite/SelectPaymethod";
import LiteDashboard from "../components/pages/Lite/LiteDashboard";
import Settings from "../components/pages/Lite/Settings";
import P2pSettings from "../components/pages/Lite/P2pSettings";
import LiteNotification from "../components/pages/Lite/LiteNotification";
import BottomDrawer from "../components/pages/NewSpot";

function Routers() {
  const { isLite } = useSelector((state) => state.user);
  const location = useLocation();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          {/* Lite routes */}
          <Route
            path={ROUTES.LITEBUY}
            element={
              <PageWraper>
                <Layout>{<LiteBuy />}</Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.LITEBUYENTERAMOUNT}
            element={
              <PageWraper>
                <Layout>{<BuyAmount />}</Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.LITESELLENTERAMOUNT}
            element={
              <PageWraper>
                <Layout>{<SellAmount />}</Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.SELECTPAYMENTFORLITE}
            element={
              <PageWraper>
                <Layout>{<SelectPaymethod />}</Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.LITESETTINGS}
            element={
              <PageWraper>
                <Layout>{<Settings />}</Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.P2PLITESETTINGS}
            element={
              <PageWraper>
                <Layout>{<P2pSettings />}</Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.P2PLITENOTIFICATIONSETTINGS}
            element={
              <PageWraper>
                <Layout>{<LiteNotification />}</Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.HOME}
            element={
              <PageWraper>
                <Layout>{<HomePage />}</Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.LOGIN}
            element={
              <PageWraper>
                <Layout>
                  <Login />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.REGISTER}
            element={
              <PageWraper>
                <Layout>
                  <Register />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.LANDING}
            element={
              <PageWraper>
                <Layout>
                  <Landing />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.SECURITY_INFO}
            element={
              <PageWraper>
                <Layout>
                  <SecurityInfo />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.SECURITY_ID}
            element={
              <PageWraper>
                <Layout>
                  <SecurityId />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.S_PHOTO_ID}
            element={
              <PageWraper>
                <Layout>
                  <SPhotoId />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.FACE_ID}
            element={
              <PageWraper>
                <Layout>
                  <FaceId />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.PERSONAL_INFO}
            element={
              <PageWraper>
                <Layout>
                  <PersonalInfo />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.CITIZENSHIP}
            element={
              <PageWraper>
                <Layout>
                  <Citizenship />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.ADDRESS}
            element={
              <PageWraper>
                <Layout>
                  <Address />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.PURPOSE}
            element={
              <PageWraper>
                <Layout>
                  <Purpose />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.EXPECTATIONS}
            element={
              <PageWraper>
                <Layout>
                  <Expectations />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.SUCCESS_REGISTER}
            element={
              <PageWraper>
                <Layout>
                  <SuccessRegister />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.ONBOARDING}
            element={
              <PageWraper>
                <Layout>
                  <Onboarding />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.ONBOARDING_1}
            element={
              <PageWraper>
                <Layout>
                  <Onboarding1 />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.NOTIFICATIONS}
            element={
              <PageWraper>
                <Layout>
                  <Notifications />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.REFER}
            element={
              <PageWraper>
                <Layout>
                  <Refer />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.CONVERT}
            element={
              <PageWraper>
                <Layout>
                  <Convert />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.RECEIVE}
            element={
              <PageWraper>
                <Layout>
                  <Recieve />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.MARKET}
            element={
              <PageWraper>
                <Layout>
                  <Market />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.SECURITY}
            element={
              <PageWraper>
                <Layout>
                  <Security />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.SECURITY_STEPS}
            element={
              <PageWraper>
                <Layout>
                  <SecuritySteps />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.SECURITY_EMAIL}
            element={
              <PageWraper>
                <Layout>
                  <SecurityEmail />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.TWO_FA}
            element={
              <PageWraper>
                <Layout>
                  <TwoFA />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.CONFIRM_TWO_FA}
            element={
              <PageWraper>
                <Layout>
                  <ConfirmTwoFa />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.CONFIRM_TWO_FA_MOB}
            element={
              <PageWraper>
                <Layout>
                  <ConfirmTwoFaMobile />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.CONFIRM_TWO_FA_MOB_KYC}
            element={
              <PageWraper>
                <Layout>
                  <ConfirmTwoFaMobileKyc />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.GENERAL}
            element={
              <PageWraper>
                <Layout>
                  <General />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.TRANSACTION}
            element={
              <PageWraper>
                <Layout>
                  <Transaction />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.COIN}
            element={
              <PageWraper>
                <Layout>
                  <Coin />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.COINHISTORY}
            element={
              <PageWraper>
                <Layout>
                  <CoinHistory />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.PAIRCOIN}
            element={
              <PageWraper>
                <Layout>
                  <PairCoin />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.CRYPTO_INFO2}
            element={
              <PageWraper>
                <Layout>
                  <CryptoInfo />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.CRYPTO_INFO}
            element={
              <PageWraper>
                <Layout>
                  <BottomDrawer />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.PROFILE}
            element={
              <PageWraper>
                <Layout>{<LiteDashboard />}</Layout>
                {/* <Layout>{isLite ? <LiteDashboard /> : <Profile />}</Layout> */}
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.NOTIFICATION_SETTING}
            element={
              <PageWraper>
                <Layout>
                  <NotificationSetting />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.SELECT_ASSETS_TO_BUY}
            element={
              <PageWraper>
                <Layout>
                  <SelectAssetsToBuy />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.BUY}
            element={
              <PageWraper>
                <Layout>
                  <Buy />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.BUYPAIR}
            element={
              <PageWraper>
                <Layout>
                  <SpotBuy />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.WALLET}
            element={
              <PageWraper>
                <Layout>
                  <Wallet />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.WITHDRAW}
            element={
              <PageWraper>
                <Layout>
                  <WithdrawCoin />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.DEPOSIT}
            element={
              <PageWraper>
                <Layout>
                  <DepositCoin />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.ADD_PAYMENT_METHOD}
            element={
              <PageWraper>
                <Layout>
                  <AddPaymentMethod />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.ORDER_SUMMARY}
            element={
              <PageWraper>
                <Layout>
                  <OrderSummary />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.BUY_SENT}
            element={
              <PageWraper>
                <Layout>
                  <BuySent />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.ORDER_STATUS}
            element={
              <PageWraper>
                <Layout>
                  <OrderStatus />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.PAIR_ORDER_STATUS}
            element={
              <PageWraper>
                <Layout>
                  <PairOrderStatus />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.EMAIL_VERIFIY}
            element={
              <PageWraper>
                <Layout>
                  <VerifyEmail />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.FORGOT_PASSWORD}
            element={
              <PageWraper>
                <Layout>
                  <ForgotNew />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.CHANGE_PASSWORD}
            element={
              <PageWraper>
                <Layout>
                  <ChangePassword />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.P2PHOME}
            element={
              <PageWraper>
                <Layout>
                  <P2PHome />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.P2PORDER}
            element={
              <PageWraper>
                <Layout>
                  <P2POrders />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.P2PADDNEW}
            element={
              <PageWraper>
                <Layout>
                  <PostAdForm />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.PAYMENTMETHODS}
            element={
              <PageWraper>
                <Layout>
                  <PaymentMethods />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.RECOMENDED}
            element={
              <PageWraper>
                <Layout>
                  <Recomended />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.ADDPAYMETHOD}
            element={
              <PageWraper>
                <Layout>
                  <AddPayMethod />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.EDITPAYMETHOD}
            element={
              <PageWraper>
                <Layout>
                  <EditPaymethod />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.BUYP2P}
            element={
              <PageWraper>
                <Layout>
                  <BuyP2P />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.VERIFYORDER}
            element={
              <PageWraper>
                <Layout>
                  <VerifyOrder />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.VERIFYORDERTOSELL}
            element={
              <PageWraper>
                <Layout>
                  {/* <VerifyOrderToSell /> */}
                  <VerifyOrder />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.MYADDS}
            element={
              <PageWraper>
                <Layout>
                  <MyAdds />
                </Layout>
              </PageWraper>
            }
          />
          <Route
            path={ROUTES.SELECTMETHOD}
            element={
              <PageWraper>
                <Layout>
                  <ChooseMethod />
                </Layout>
              </PageWraper>
            }
          />
        </Routes>
      </AnimatePresence>
    </>
  );
}
export default Routers;
const PageWraper = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 10 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -10 }}
      transition={{ duration: 0.2 }}
    >
      {children}
    </motion.div>
  );
};
